<template>
    <div style="color:whitesmoke;text-align: center;">
    <!--<div> choix 3-->
        <div class="row">
            <div class="col-12">
                <h4><span>Trouvé un identifiant dans un livre ?</span></h4>
            </div>
        </div>
        <div class="searchChain">
            <div class="row mr-4">
                <div class="col-12">
                    Saisissez-le ici :
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 input-group mb-2">
                    <div style="margin: auto;display: inherit;">
                        <input type="text" class="form-control" name="codeChain" id="codeChain" placeholder="..." maxlength="15" style="max-width:220px" v-model="codeChain" v-on:keyup.enter="searchChain" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" style="color: whitesmoke;" @click="searchChain"><font-awesome-icon icon="search"/></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSearch } from '@fortawesome/free-solid-svg-icons'
    library.add(faSearch);
    export default {
        name: "SearchChain",
        props: {
            initialCodeChain: String
        },
        data() {
            return {
                codeChain : ""
            };
        },
        methods: {
            searchChain(){
                this.$router.push({
                    name: "DetailChain",
                    path: 'register',
                    params: {codeChain:this.codeChain}
                });
                window.scrollTo(0, 0);
            }
        },
        created() {
            this.codeChain = this.initialCodeChain;
        }
    }
</script>