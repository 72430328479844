<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label for="password">Nouveau mot de passe</label>
                <input type="password" class="form-control" name="passwordNew" maxlength="50" id="passwordNew" v-model="password" v-on:input="resetControlsAndError"/>
                <small id="rulePassword" class="form-text text-muted">Plus de 5 caractères, au moins un chiffre et une ponctuation</small>
            </div>
            <div class="form-group">
                <label for="password">Répéter le nouveau mot de passe</label>
                <input type="password" class="form-control" name="passwordNewBis" maxlength="50" id="passwordNewBis" v-model="passwordBis" v-on:input="resetControlsAndError" v-on:keyup.enter="updatePassword"/>
            </div>
        </div>
    </div>
    <div class="row" v-if="messageControl !== ''">
        <div class="col-12">
            <div class="alert alert-danger">
                {{messageControl}}
            </div>
        </div>
    </div>
    <div class="row" v-if="!modifySuccessful">
        <div class="col-12">
            <button class='btn btn-primary' @click="updatePassword"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isUpdatePasswordInProgress"/>Valider</button>
        </div>
    </div>
    <div class="row" v-if="modifySuccessful">
        <div class="col-12">
            <div class="alert alert-success">
                <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Votre mot de passe a été enregistré.
            </div>
        </div>
    </div>
    <div class="row" v-if="messageError !== ''" style="margin-top:15px;">
        <div class="col-12">
            <div class="alert alert-danger">
                {{messageError}}
            </div>
        </div>
    </div>
</template>
<script>
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    library.add(faCheckCircle, faSpinner);
    import utils from "@/utils/utils.js";
    export default {
        name: "MustChangePassword",
        emits: ["manage-user-connected", "must-validate-code"],
        data() {
            return {
                password: "",
                passwordBis: "",
                modifySuccessful: false,
                messageError: "",
                messageControl: "",
                isUpdatePasswordInProgress: false
            };
        },
        methods: {
            updatePassword() {

                if(this.isUpdatePasswordInProgress){
                    return;
                }

                if(this.password.length === 0){
                    this.messageControl = "Veuillez renseigner le nouveau mot de passe";
                    return;
                }
                if(this.passwordBis.length === 0){
                    this.messageControl = "Veuillez répéter le nouveau mot de passe";
                    return;
                }
                if(this.password !== this.passwordBis){
                    this.messageControl = "Le second mot de passe est différent du premier";
                    return;
                }
                if(this.password.length < 6){
                    this.messageControl = "Veuillez renseigner un mot de passe avec plus de 5 caractères";
                    return;
                }
                if(this.password.length > 50){
                    this.messageControl = "Le mot de passe doit comporter 50 caractères au maximum.";
                    return;
                }
                if(!this.password.match(/\d/gi)){
                    this.messageControl = "Le mot de passe doit comporter au moins un chiffre";
                    return;
                }
                if(!this.password.match(/[_²&"'(-)=^$¤*!:;,+\\*/~#{[|`^@\]}¨£%§.?°<>]/gi)){
                    this.messageControl = "Le mot de passe doit comporter au moins un caractère spécial, comme une ponctuation";
                    return;
                }

                this.isUpdatePasswordInProgress = true;

                var parameters = {
                    jwt:utils.getCookie('jwt'),
                    password: this.password,
                    passwordBis: this.passwordBis,
                };
                var request = new Request("/api" + "/must_change_password", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                    'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            if(data.isValidationCode){
                                this.$emit("must-validate-code");
                            }else{
                                utils.setCookie("jwt", data.jwt, 1);
                                this.modifySuccessful = true;
                                setTimeout(function () {
                                    //Notify parent component of the connected user
                                    this.$emit("manage-user-connected", data.user);
                                }.bind(this), 1500);
                            }
                        }else{
                            this.messageError = data.message;
                        }
                        this.isUpdatePasswordInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isUpdatePasswordInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isUpdatePasswordInProgress = false;
                });
            },
            resetControlsAndError(){
                this.modifySuccessful = false;
                this.messageError= "";
                this.messageControl = "";
            }
        }
    }
</script>