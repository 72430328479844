<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h4 class="title">
                    <span>Nouvelle Chaîne N°<b>{{ this.$route.params.codeChain }}</b></span>
                </h4>
                <p>
                    Munissez-vous de votre plus belle plume et reporter lisiblement dans la première page du livre :
                    <ul>
                        <li>
                            L'adresse du site : www.bookchain.fr
                        </li>
                        <li>
                            L'identifiant de votre nouvelle chaîne : {{ this.$route.params.codeChain }}
                        </li>
                    </ul>
                </p>
            </div>
        </div>
       
        <div class="row">
            <div class="col-12">
                <h1 class="texte_impression texte_identifiant description">www.bookchain.fr<br/>id : {{ this.$route.params.codeChain }}</h1>
                <img src="@/assets/images/book-657618_1280.jpg" style="width:100%"/>
            </div>
        </div>

        <div class="row" style="margin-top: 15px;">
            <div class="col-12" style="text-align:center;">
                <button class='btn btn-primary' @click="redirectDetailChain" data-dismiss="modal">Consulter la nouvelle chaîne</button>
            </div>
        </div>
   </div>
</template>

<script>
    export default {
        name: "CreateChain",
        methods: {
            redirectDetailChain() {
                this.$router.push({
                    name: "DetailChain",
                    path: 'register',
                    params: {codeChain:this.$route.params.codeChain}
                });
            }
        }
    }
</script>

<style>
    .texte_impression{
        position: absolute;
        margin-top: 40px;
        font-family: "ChopinScript", Helvetica, Arial;
    }

    @media (max-width: 500px) {
        .texte_impression{
            margin-top:20px;
            margin-left: 47%;
            font-size: 21px;
        }
    }
    @media (min-width: 500px) and (max-width: 576px) {
        .texte_impression{
            margin-top:20px;
            margin-left: 49%;
            font-size: 27px;
        }
    }
    @media (min-width: 576px) and (max-width: 768px) {
        .texte_impression{
            margin-top:20px;
            margin-left: 270px;
            font-size: 30px;
        }
    }
    @media (min-width: 768px) and (max-width: 992px) {
        .texte_impression{
            margin-top:24px;
            margin-left: 365px;
            font-size: 40px;
        }
    }
    @media (min-width: 992px) and (max-width: 1200px) {
        .texte_impression{
            margin-left: 510px;
            font-size: 54px;
        }
    }
    @media (min-width: 1200px) {
      .texte_impression{
            margin-left: 620px;
            font-size: 60px;
        }
    }
</style>