<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h4 class="title">
                    <span>
                        Erreur 404
                    </span>
                </h4>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12 my-auto" style="text-align:center;">
                <h3>Il semblerait que cette page n'existe pas ou plus.</h3>
            </div>
            <div class="col-lg-7 col-md-12">
                <img src="@/assets/images/book-5562200_1280.jpg" style="width:100%;"/>
            </div>
        </div>
    </div>
</template>