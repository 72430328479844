<template>
    <div class="container" v-if="messageError !== undefined && messageError !== ''">
        <div class="row">
            <div class="col-12 alert alert-danger">
                {{messageError}}
            </div>
        </div>
    </div>
    <div class="container" style="min-height: 100%;height: 100%;" v-for="linkToValidate in linksToValidate" :key="linkToValidate">
        <div class="row">
            <div class="col">
                <h4 class="title">
                    <span>
                        Chaîne N°<b>{{linkToValidate.codeChain}}</b>
                    </span>
                </h4>
            </div>
        </div>

        <div class="row">
            <div class="col-5 col-sm-4 col-lg-3 col-xl-2 mb-4">
                <img :src="linkToValidate.urlImage" @error="setNoBookCover(linkToValidate)" style="width:100%;"/>
            </div>
            <div class="col-7 col-sm-8 col-lg-3 col-xl-4">
                <div class="row">
                    <div class="col-12">
                        <b>Titre</b> : {{linkToValidate.title}}
                    </div>
                </div>
                <div class="row" v-if="linkToValidate.authors !== ''">
                    <div class="col-12">
                        <b>Auteur(s)</b> : {{linkToValidate.authors}}
                    </div>
                </div>
                <div class="row" style="margin-top: 15px;">
                    <div class="col-12">
                        <button class='btn btn-primary' @click="redirectChain(linkToValidate.codeChain)">Voir la chaîne</button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                <div class="row">
                    <div class="col-12">
                        {{linkToValidate.firstName}} {{linkToValidate.lastName}} {{linkToValidate.pseudonym}} s'est déclaré(e) comme prochain maillon de la chaîne le {{linkToValidate.creationDate}}.
                        Confirmez-vous cette demande ?
                    </div>
                </div>
                <div class="row" style="margin-top: 15px;">
                    <div class="col-12" style="text-align: center;" v-if="!linkToValidate.isValidate && !linkToValidate.isRefused">
                        <button class='btn btn-primary mr-4' @click="validateLink(linkToValidate)"><font-awesome-icon :icon="['far', 'thumbs-up']" class="mr-2"/>Valider</button>
                        <button class='btn btn-danger' @click="refuseLink(linkToValidate)"><font-awesome-icon :icon="['far', 'thumbs-down']" class="mr-2"/>Refuser</button>
                    </div>
                    <div class="col-12" v-else-if="linkToValidate.isValidate">
                        <div class="alert alert-success" >
                            <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Le maillon a été validé.
                        </div>
                    </div>
                    <div class="col-12" v-else>
                        <div class="alert alert-success" >
                            <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Le maillon a été refusé.
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 15px;">
                    <div class="col-12" v-if="linkToValidate.error !== undefined && linkToValidate.error !== ''">
                        <div class="alert alert-danger">
                            {{linkToValidate.error}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" v-if="linksToValidate !== undefined && linksToValidate.length === 0">
        <div class="row">
            <div class="col-12">
                Auncun lien à valider
            </div>
        </div>
    </div>
</template>
<script>
    import utils from "@/utils/utils.js";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faThumbsUp, faThumbsDown, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
    library.add(faThumbsUp, faThumbsDown, faCheckCircle);
    export default {
        name: "LinksToValidate",
        props: {
            user: Object
        },
        emits: ["manage-user-connected", "reset-links-to-validate"],
        data() {
            return {
                linksToValidate: undefined,
                noBookCover: require('@/assets/images/no_book_cover.png'),
                messageError: ""
            };
        },
        created() {
            this.getLinksToValidate();
        },
        methods: {
            redirectChain(codeChain){
                this.$router.push({
                    name: "DetailChain",
                    path: 'register',
                    params: {codeChain:codeChain}
                });
            },
            getLinksToValidate(){
                var jwt = utils.getCookie('jwt');

                var request = new Request("/api" + "/get_links_to_validate", {
                    method: "POST",
                    body: JSON.stringify({ jwt:jwt, timeZoneOffset:new Date().getTimezoneOffset() }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data => {
                        if (response.ok) {
                            this.linksToValidate = data.linksToValidate;
                            for(var index in this.linksToValidate){
                                this.linksToValidate[index].isValidate = false;
                                this.linksToValidate[index].isRefused = false;
                                //this.linksToValidate[index].urlImage = 'https://images.isbndb.com/covers/' + this.linksToValidate[index].codeChain.substring(6, 8) + '/' + this.linksToValidate[index].codeChain.substring(8, 10) + '/97' + this.linksToValidate[index].codeChain.substring(0, 11) + '.jpg';
                                this.linksToValidate[index].urlImage = 'https://pictures.abebooks.com/isbn/97'+ this.linksToValidate[index].codeChain.substring(0, 11) + "-us-300.jpg";
                            }
                        }else if(response.status === 401){
                            this.linksToValidate = undefined;
                            // remove jwt
                            utils.setCookie("jwt", "", 1);
                            this.$emit("manage-user-connected", undefined);
                        }
                    }).catch((error) => {
                        console.error(error);
                        this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                });
            },
            setNoBookCover(linkToValidate) {
                linkToValidate.urlImage = this.noBookCover;
            },
            validateLink(chainToValidate){
                chainToValidate.error = undefined;

                var parameters = {
                    jwt:utils.getCookie('jwt'),
                    codeChain: chainToValidate.codeChain
                };

                var request = new Request("/api" + "/validate_link", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            //Validation OK
                            chainToValidate.isValidate = true;
                            this.$emit("reset-links-to-validate");
                        }else {
                            if(response.status === 401){
                                this.linksToValidate = undefined;
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                                this.$emit("manage-user-connected", undefined);
                            }
                            chainToValidate.error = data.message;
                        }
                    }).catch((error) => {
                        console.error(error);
                        chainToValidate.error = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    });
                }).catch((error) => {
                    console.error(error);
                    chainToValidate.error = "Erreur du serveur. Veuillez réessayer ultérieurement";
                });
            },
            refuseLink(chainToRefuse){
                chainToRefuse.error = undefined;

                var parameters = {
                    jwt:utils.getCookie('jwt'),
                    codeChain: chainToRefuse.codeChain
                };

                var request = new Request("/api" + "/refuse_link", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            //Validation OK
                            chainToRefuse.isRefused = true;
                            this.$emit("reset-links-to-validate");
                        }else {
                            if(response.status === 401){
                                this.linksToValidate = undefined;
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                                this.$emit("manage-user-connected", undefined);
                            }
                            chainToRefuse.error = data.message;
                        }
                    }).catch((error) => {
                        console.error(error);
                        chainToRefuse.error = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    });
                }).catch((error) => {
                    console.error(error);
                    chainToRefuse.error = "Erreur du serveur. Veuillez réessayer ultérieurement";
                });
            }
        }
    } 
</script>