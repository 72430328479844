import { createWebHistory, createRouter } from "vue-router";
import CreateChain from '@/components/CreateChain.vue';
import DetailChain from '@/components/DetailChain.vue';
import DetailUser from '@/components/DetailUser.vue';
import Home from '@/components/Home.vue';
import LinksToValidate from '@/components/LinksToValidate.vue';
import NotFound from '@/components/NotFound.vue';
import PersonalData from '@/components/PersonalData.vue';
import TermsOfUse from '@/components/TermsOfUse.vue';
import UpdatePassword from '@/components/UpdatePassword.vue';
import UpdateAccount from '@/components/UpdateAccount.vue';

const routes = [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: '/chain/:codeChain',
      name: 'DetailChain',
      component: DetailChain
    },
    {
      path: '/user/:idUser',
      name: 'DetailUser',
      component: DetailUser
    },
    {
      path: "/updateAccount",
      name: "UpdateAccount",
      component: UpdateAccount,
    },
    {
      path: "/updatePassword",
      name: "UpdatePassword",
      component: UpdatePassword,
    },
    {
      path: "/createChain/:codeChain",
      name: "CreateChain",
      component: CreateChain,
    },
    {
      path: "/termsOfUse",
      name: "TermsOfUse",
      component: TermsOfUse,
    },
    {
      path: "/linksToValidate",
      name: "LinksToValidate",
      component: LinksToValidate
    },
    {
      path: "/personalData",
      name: "PersonalData",
      component: PersonalData,
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
    }
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeEach(() => {
    window.scrollTo(0, 0);
  })

  export default router;