<template>
    <div :class="customClass" ref="mapDivRef"></div>
</template>

<script>
    import { ref, onMounted, watch } from "vue";
    import utils from "@/utils/utils.js";
    export default {
        name: "Map",
        emits:["update-marker-position"],
        props: {
            customClass: {
                type: String,
                required: true
            },
            positionMarker:{
                type: Object,
                required: false
            },
            linksDetail:{
                type: Array,
                required: false
            },
            temporaryLink:{
                type: Object,
                required: false
            },
            flagResetLinks: Boolean,
            initialZoom:{
                type: Number,
                required: true
            }
        },
        computed: {
        style () {
            return 'map ' + this.customClass;
        }
        },

        setup(props, { emit }) {
            // the google map object
            const map = ref(null);
            // the map element in the templste
            const mapDivRef = ref(null);

            let currentMarkers = [];
            let currentPolylines = [];
            let currentInfoWindow = undefined;

            // load in the google script
            onMounted(() => {
                // key is is the .env file
                const key = "AIzaSyA7NPi9IMKQTfrFJo5CKBjgl-49cO0zL_8";
                //const key ="";
                // create the script element to load
                const googleMapScript = document.createElement("SCRIPT");
                googleMapScript.setAttribute(
                    "src",
                    `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap`
                );
                googleMapScript.setAttribute("defer", "");
                googleMapScript.setAttribute("async", "");
                document.head.appendChild(googleMapScript);
            });

            /*
            * Remove the map from the marker &polylines
            */
            const clearMarkersAndPolylines = () => {
                currentMarkers.forEach(m => {
                    m.setMap(null);
                });
                currentMarkers = [];
                if(currentInfoWindow !== undefined){
                    currentInfoWindow.close();
                    currentInfoWindow = undefined;
                }

                currentPolylines.forEach(m => {
                    m.setMap(null);
                });
                currentPolylines = [];
            }

            const loadMapMarker = () => {
                if(props.positionMarker !== undefined && window.google !== undefined){
                    if(currentMarkers.length === 0){
                        map.value.addListener('click', function(evt) {
                            setTimeout(function () {
                                emit("update-marker-position", evt);
                            }, 300);
                        });
                        
                        const center = new window.google.maps.LatLng(props.positionMarker.latitude, props.positionMarker.longitude);
                        map.value.panTo(center);
                        map.value.setZoom(props.initialZoom|0);
                    }
                    
                    clearMarkersAndPolylines();

                    var myLatlng = new window.google.maps.LatLng(props.positionMarker.latitude, props.positionMarker.longitude);
                    var marker = new window.google.maps.Marker({
                        position: myLatlng,
                        draggable:true,
                        title:"Indiquez votre adresse ou votre ville"
                    });
                    marker.setMap(map.value);

                    window.google.maps.event.addListener(marker, 'dragend', function (evt) {
                        emit("update-marker-position", evt);
                    });
                    
                    currentMarkers.push(marker);
                }
            };

            const loadPath = () => {
                if(props.linksDetail !== undefined && window.google !== undefined && props.linksDetail.length > 0){

                    clearMarkersAndPolylines();

                    //Center the map on the first link
                    const center = new window.google.maps.LatLng(props.linksDetail[0].latitude, props.linksDetail[0].longitude);
                    map.value.panTo(center);
                    map.value.setZoom(10);

                    //Add markers
                    for (var i = 0, n = props.linksDetail.length; i < n; i++) {
                        var marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(props.linksDetail[i].latitude, props.linksDetail[i].longitude),
                            title:(props.linksDetail[i].pseudonym !=="" ? props.linksDetail[i].pseudonym : (props.linksDetail[i].firstName + " " + props.linksDetail[i].lastName)) + " - " + props.linksDetail[i].creationDate
                        });
                        if(props.linksDetail[i].isInValidation){
                            marker.setOpacity(0.3);
                        }

                        var infowindow = new window.google.maps.InfoWindow();
                        var content = "<b>"+(props.linksDetail[i].pseudonym !=="" ? props.linksDetail[i].pseudonym : (props.linksDetail[i].firstName + " " + props.linksDetail[i].lastName)) + " - " + props.linksDetail[i].creationDate + "</b><br/>"+
                        props.linksDetail[i].comment.replace(/\n/gi, '<br/>') + (
                        props.linksDetail[i].isPrivateProfile ? "" : "<br/><br/><a href='"+ window.location.protocol + "//" + window.location.host + "/user/" + props.linksDetail[i].idUser + "'>Voir la page de l'utilisateur</a>");
                        window.google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){ 
                            return function() {
                                infowindow.setContent(content);
                                infowindow.open(map, marker);
                            };
                        })(marker, content, infowindow));  

                        marker.setMap(map.value);
                        currentMarkers.push(marker);
                        currentInfoWindow = infowindow;
                    }

                    //Fit bounds
                    const bounds = new window.google.maps.LatLngBounds();
                    for (i = 0, n = props.linksDetail.length; i < n; i++) {
                        bounds.extend(new window.google.maps.LatLng(props.linksDetail[i].latitude, props.linksDetail[i].longitude));
                    }
                    map.value.fitBounds(bounds);

                    const arrow = {
                        path: 'M 0,0 3,5 -3,5 0,0 z', // 0,0 is the tip of the arrow
                        fillColor: '#b72c24',
                        fillOpacity: 1.0,
                        strokeColor: '#b72c24',
                        strokeWeight: 1,
                    };
                    const temporaryArrow = {
                        path: 'M 0,0 3,5 -3,5 0,0 z', // 0,0 is the tip of the arrow
                        fillColor: '#b72c24',
                        fillOpacity: 0.4,
                        strokeColor: '#b72c24',
                        strokeWeight: 1,
                    };
                    for ( i = 0; i < (props.linksDetail.length - 1); i++) {
                        var coordinates = new Array();
                        for (var j = 0; j < 2; j++) {
                            coordinates[j] = new Object();
                            coordinates[j].lat = props.linksDetail[i+j].latitude;
                            coordinates[j].lng = props.linksDetail[i+j].longitude;
                        }
                        var path = new window.google.maps.Polyline({
                            path: coordinates,
                            geodesic: true,
                            strokeColor: "#b72c24",
                            strokeOpacity: props.linksDetail[i+1].isInValidation ? 0.4 : 1.0,
                            strokeWeight: 2,
                            icons: [{
                                icon: props.linksDetail[i+1].isInValidation ? temporaryArrow : arrow,
                                offset: '100%',
                            }]
                        });
                        path.setMap(map.value);
                        currentPolylines.push(path);
                    }
                }
            };

            watch(
                () => props.positionMarker,
                (positionMarkerNew, positionMarkerOld)=>{
                    if(positionMarkerNew.longitude !== positionMarkerOld.longitude || positionMarkerNew.latitude !== positionMarkerOld.latitude){
                        loadMapMarker();
                    }
                }
            );

            watch(
                () => props.flagResetLinks,
                ()=>{
                    loadPath();
                }
            );

            /**
             * this function is called as soon as the map is initialized
             */
            window.initMap = () => {
                map.value = new window.google.maps.Map(mapDivRef.value, {
                    mapTypeId: "terrain",
                    zoom: props.initialZoom|0,
                    disableDefaultUI: false,
                    center: { lat: 47.2, lng: -1.55 }
                });

                loadMapMarker();
                loadPath();
            };

            return {
                mapDivRef
            };
        },

        unmounted(){
            utils.removeGoogleMapScript();
        },
        mounted(){
            window.redirectToUserProfil= (idUser) => {
                this.$router.push({
                    name: "DetailUser",
                    params: {idUser:idUser}
                });
            };
        }
    }
</script>
<style>
    .map {
        width: 100%;
        height: 100%;
        background-color: azure;
    }
</style>