<template>
    <div class="container" style="padding-bottom: 0px;">
        <div class="row">
            <div class="col-md-12 col-lg-6 separator-right title" style="padding-top:15px;">
                <SearchChain/>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row">
                    <div class="col-12">
                        <div class="alert alert-secondary" role="alert">
                            <font-awesome-icon icon="info" class="mr-2"/>
                            En période d'épidémie, il est conseillé de mettre de côté les livres que vous recevez pendant deux à trois semaines.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>
                            BookChain vous propose de créer et de participer à des chaînes de partage de livres.
                        </p>
                        <p>
                            A la croisée des bibliothèques et des boîtes à livres, BookChain permet de suivre vos livres préférés au travers de votre ville, région, pays ou monde. Comme des bouteilles à la mer.
                        </p>
                        <p>
                            Une expérience ludique pour créer de nouvelles histoires.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div :style="{ backgroundImage: 'url(' + require('@/assets/images/livre_bibliotheque.jpg') + ')', backgroundSize: 'cover', backgroundPosition: 'center center', height: '30vh', width: '100%' }">
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <h4 class="title">
                    <span>Mode d'emploi</span>
                </h4>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 col-lg-7 separator-right">
                <div class="row">
                    <div class="col-sm-3 col-4 offset-1 offset-sm-2">
                        <img src="@/assets/images/manual_arrow_1.png" style="width:88%;max-width:100px;"/>
                    </div>
                    <div class="col-7 my-auto" style="padding-left:0px;">
                        <p>
                            Une lectrice de Paris a adoré un livre et souhaite le faire connaître à une de ses connaissances.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-1 d-none d-sm-block">
                        <span style="font-size:30px;margin-right:10px;">1</span>
                    </div>
                    <div class="col-5 col-sm-4 my-auto">
                        <img src="@/assets/images/manual_1.png" style="width:88%;"/>
                    </div>
                    <div class="col-7 my-auto" style="padding-left:0px;">
                        Elle récupère auprès de BookChain un identifiant et le reporte dans la première page du livre.
                        La chaîne est ainsi créée et l'identifiant permet de reconnaître de manière unique le livre qu'elle a entre les mains.
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-4 offset-1 offset-sm-2 my-auto">
                        <img src="@/assets/images/manual_arrow.png" style="width:88%;max-width:100px;"/>
                    </div>
                    <div class="col-7 my-auto" style="padding-left:0px;">
                        A l'occasion d'un voyage à londres, elle transmet le livre à sa connaissance.
                    </div>
                </div>
                <div class="row">
                    <div class="col-1 d-none d-sm-block">
                        <span style="font-size:30px;margin-right:10px;">2</span>
                    </div>
                    <div class="col-5 col-sm-4 my-auto">
                        <img src="@/assets/images/manual_2.png" style="width:88%;"/>
                    </div>
                    <div class="col-7 my-auto" style="padding-left:0px;">
                        Le londonien utilise l'identifiant dans le livre pour indiquer via BookChain qu'il possède le livre. Il représente donc le deuxième maillon de la chaîne.
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-4 offset-1 offset-sm-2">
                        <img src="@/assets/images/manual_arrow.png" style="width:88%;max-width:100px;"/>
                    </div>
                    <div class="col-7 my-auto" style="padding-left:0px;">
                        Après avoir lu le livre, pris au jeu, il fait parvenir le livre à une de ses collègues.
                    </div>
                </div>
                <div class="row">
                    <div class="col-1 d-none d-sm-block">
                        <span style="font-size:30px;margin-right:10px;">...</span>
                    </div>
                    <div class="col-5 col-sm-4">
                        <img src="@/assets/images/manual_3.png" style="width:88%;"/>
                    </div>
                    <div class="col-7 my-auto" style="padding-left:0px;">
                        Comme le londonien, les lecteurs suivants utilisent l'identifiant pour se déclarer comme maillon de la chaîne dans BookChain.
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-5 my-auto">
                <img src="@/assets/images/home_carte.jpg" class="mt-4" style="max-width:100%;width:100%;"/>
                <p>
                    La consultation d'une chaîne permet de visualiser les différents maillons grâce à une carte interactive.
                </p>
                <p style="margin-top:60px;">
                    BookChain propose aux maillons d'écrire un texte qui participera à l'histoire de la chaîne. Par exemple :
                    <ul>
                        <li>
                            A quelle occasion ils ont reçu le livre,
                        </li>
                        <li>
                            Un petit mot pour la personne qui leur a transmis le livre (le maillon qui les précède),
                        </li>
                        <li>
                            Ce qu'ils ont pensé du livre,
                        </li>
                        <li>
                            Un clin d'oeil au maillon suivant,
                        </li>
                        <li>
                            ...
                        </li>
                    </ul>
                </p>
            </div>
        </div>
    </div>
    <div style="background-color: #343a40;">
        <div class="container" style="background-color : #343a40;">
            <div class="row">
                <div class="col-12">
                    <SearchIsbn :user="user"/>
                </div>
            </div>
        </div>
    </div>
    <div class="container" v-if="lastChains !== undefined">
        <div class="row">
            <div class="col-12">
                <h4 class="title">
                    <span>Les dernières chaînes créées</span>
                </h4>
            </div>
        </div>
        
        <div class="row">
            <div class="col-6 col-md-4 col-lg-3 col-xl-2" v-for="chain in lastChains" :key="chain">
                <div class="row">
                    <div class="col-12">
                        <img :src="chain.urlImage" @error="setNoBookCover(chain)" style="width:100%;"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b>Titre</b> : {{chain.title}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b>Auteur(s)</b> : {{chain.authors}}
                    </div>
                </div>
                <div class="row" style="margin-bottom: 30px;">
                    <div class="col-12">
                        <router-link :to="{ name: 'DetailChain', params: { codeChain: chain.codeChain }}"><button class='btn btn-primary'>Chaîne : {{chain.codeChain}}</button></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faInfo } from '@fortawesome/free-solid-svg-icons'
    import SearchIsbn from "@/components/SearchIsbn.vue";
    import SearchChain from "@/components/SearchChain.vue";
    
    library.add(faInfo);
    export default {
        name: "Home",
        components: { SearchIsbn, SearchChain },
        props: {
            user: Object
        },
        data() {
            return {
                noBookCover: require('@/assets/images/no_book_cover.png'),
                lastChains : undefined,
            };
        },
        created() {
            var request = new Request("/api" + "/get_last_chains", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            fetch(request).then(response => {
                response.json().then(data => {
                    if (response.ok) {
                        this.lastChains = data.lastChains;
                        for(var index in this.lastChains){
                            //this.notCreatedChains[index].urlImage = 'https://images.isbndb.com/covers/' + this.notCreatedChains[index].codeChain.substring(6, 8) + '/' + this.notCreatedChains[index].codeChain.substring(8, 10) + '/97' + this.notCreatedChains[index].codeChain.substring(0, 11) + '.jpg';
                            this.lastChains[index].urlImage = "https://pictures.abebooks.com/isbn/97"+ this.lastChains[index].codeChain.substring(0, 11) + "-us-300.jpg"
                        }
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }).catch((error) => {
                console.error(error);
            });
        },
        methods:{
            setNoBookCover(chain) {
                chain.urlImage = this.noBookCover;
            }
        }
    }
</script>
<style>
    .title .searchChain{
        margin-top:70px;
        margin-bottom:70px;
        color: #2c3e50;
    }
    .title h4 {
        text-align: initial !important;
    }

    .title .searchChain button{
        color: #2c3e50 !important;
    }
</style>