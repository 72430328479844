<template>
    <div class="loading-screen" v-show="loading" style="backgroundColor:rgba(255,255,255,0.8)">
        <div>
            <div class="loading-circle"></div>
            <p class="loading-text">{{text}}</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            text: 'Chargement'
        }
    },
    props: {
        loading: Boolean
    }
}
</script>
<style scoped>
    .loading-screen {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        flex-direction: column;
        user-select: none;
    }
    .loading-circle {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        border: 2px solid transparent;
        border-left-color: #ababab;
        animation: circleanimation .45s linear infinite
    }
    .loading-text {
        margin-top: 15px;
        color: #808080;
        text-align: center;
    }
</style>