<template>
    <div class="modal fade" id="modalLogin" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="isCreationAccount">Création de votre compte</h5>
                    <h5 class="modal-title" v-else-if="isValidationCode">Saisie du code de validation</h5>
                    <h5 class="modal-title" v-else-if="isForgotPassword">Mot de passe oublié</h5>
                    <h5 class="modal-title" v-else-if="isMustChangePassword">Modification du mot de passe temporaire</h5>
                    <h5 class="modal-title" v-else>Connexion</h5>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalLogin">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" v-if="isCreationAccount">
                    <CreateAccount @manage-user-connected="manageUserConnected($event)" @return-to-login="returnToLogin()"/>
                </div>
                <div class="modal-body" v-else-if="isValidationCode">
                    <ValidationCode @manage-user-connected="manageUserConnected($event)"/>
                </div>
                <div class="modal-body" v-else-if="isForgotPassword">
                    <ForgotPassword :initialEmail="email" @return-to-login="returnToLogin()"/>
                </div>
                <div class="modal-body" v-else-if="isMustChangePassword">
                    <MustChangePassword @manage-user-connected="manageUserConnected($event)" @must-validate-code="mustValidateCode"/>
                </div>
                <div class="modal-body" v-else>
                    <div class="row">
                        <div class="col-7" style="border-right: 3px solid #fff;">
                            <div class="form-group">
                                <label for="email">Adresse courriel</label>
                                <input type="email" class="form-control" name="email" id="email" v-model="email" v-on:input="resetControlsAndError"/>
                            </div>
                            <div class="form-group">
                                <label for="password">Mot de passe</label>
                                <input type="password" class="form-control" name="password" id="password" v-model="password" v-on:input="resetControlsAndError" v-on:keyup.enter="this.$refs.buttonLogin.click();"/>
                                <small id="forgotPassword" class="form-text text-muted"><a href="#" @click="resetPassword">Mot de passe oublié ? Cliquer pour réinitialiser.</a></small>
                            </div>
                            <div class="row" v-if="messageControl !== ''">
                                <div class="col-12">
                                    <div class="alert alert-danger">
                                        {{messageControl}}
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-success" v-if="isLoginSuccessful">
                                <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Email et mot de passe valides
                            </div>
                            <div class="alert alert-danger" v-if="messageError !== ''">
                                {{messageError}}
                            </div>
                            <div v-if="!isLoginSuccessful">
                                <button class='btn btn-primary' ref="buttonLogin" @click="login"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isLoginInProgress"/>Valider</button>
                            </div>
                        </div>
                        <div class="col-5 my-auto" style="text-align:center;">
                            Nouveau ?
                            <br/>
                            <button class='btn btn-primary' @click="createAccount" style="margin-top:20px;">Créer un compte</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import utils from "@/utils/utils.js";
    import CreateAccount from "@/components/CreateAccount.vue";
    import ValidationCode from "@/components/ValidationCode.vue";
    import ForgotPassword from "@/components/ForgotPassword.vue";
    import MustChangePassword from "@/components/MustChangePassword.vue";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
    library.add(faCheckCircle, faSpinner);
    export default {
        name: "Login",
        components: { CreateAccount, ValidationCode, ForgotPassword, MustChangePassword },
        emits: ["manage-user-connected"],
        data() {
            return {
                email: "",
                password: "",
                isLoginSuccessful: false,
                messageControl: "",
                messageError: "",
                isCreationAccount: false,
                isValidationCode: false,
                isForgotPassword: false,
                isMustChangePassword: false,
                isLoginInProgress: false
            };
        },
        methods: {
            login() {

                if(this.isLoginInProgress){
                    return;
                }

                this.resetControlsAndError();

                if(this.email.length === 0){
                    this.messageControl = "Veuillez renseigner votre adresse courriel.";
                    return;
                }
                if(this.password.length === 0){
                    this.messageControl = "Veuillez renseigner votre mot de passe.";
                    return;
                }

                this.isLoginInProgress = true;

                var parameters = {
                    email: this.email,
                    password: this.password,
                    language: "fr"
                };

                var request = new Request("/api" + "/login", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data => {
                        if (response.ok) {
                            this.isLoginSuccessful = true;
                    
                            setTimeout(function () {
                                // store jwt to cookie
                                utils.setCookie("jwt", data.jwt, 1);
                                if(data.isValidationCode){
                                    this.isValidationCode = true;
                                }else if(data.isMustChangePassword){
                                    this.isMustChangePassword = true;
                                }else{
                                    //Close the modal
                                    this.$refs.closeModalLogin.click();
                                    //Notify parent component of the connected user
                                    this.$emit("manage-user-connected", data.user);
                                }
                                this.reset();
                            }.bind(this), 1500);
                        } else {
                            this.messageError = data.message;
                            this.password = "";
                        }
                        this.isLoginInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.password = "";
                        this.isLoginInProgress = true;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.password = "";
                    this.isLoginInProgress = true;
                });
            },
            createAccount(){
                this.isCreationAccount = true;
            },
            returnToLogin(){
                this.reset();
                this.isCreationAccount = false;
                this.isForgotPassword = false;
            },
            manageUserConnected(user){
                this.isCreationAccount = false;
                this.isForgotPassword = false;
                this.isMustChangePassword = false;
                this.isValidationCode = false;

                this.$refs.closeModalLogin.click();
                this.$emit("manage-user-connected", user);
            },
            resetPassword(){
                this.isForgotPassword = true;
            },
            resetControlsAndError(){
                this.isLoginSuccessful = false;
                this.messageError= "";
                this.messageControl = "";
            },
            reset(){
                this.resetControlsAndError();
                this.email = "";
                this.password = "";
                this.isCreationAccount = false;
            },
            mustValidateCode(){
                this.isCreationAccount = false;
                this.isForgotPassword = false;
                this.isMustChangePassword = false;
                this.isValidationCode = true;
            }
        }
    }
</script>