<template>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="form-group">
                <label for="firstName">Utiliser un pseudonyme ?</label>
                <input type="checkbox" class="ml-3" v-model="isUsePseudonym"/>
            </div>
            <div class="form-group" v-if="isUsePseudonym">
                <label for="lastName">Pseudonyme</label>
                <input type="text" class="form-control" name="pseudonym" maxlength="128" id="pseudonym" v-model="pseudonym" v-on:input="resetControlsAndError"/>
            </div>
            <div class="form-group" v-if="!isUsePseudonym">
                <label for="firstName">Prénom</label>
                <input type="text" class="form-control" name="firstName" maxlength="128" id="firstName" v-model="firstName" v-on:input="resetControlsAndError"/>
            </div>
            <div class="form-group" v-if="!isUsePseudonym">
                <label for="lastName">Nom</label>
                <input type="text" class="form-control" name="lastName" maxlength="128" id="lastName" v-model="lastName" v-on:input="resetControlsAndError"/>
            </div>
            <div class="form-group">
                <label for="email">Adresse courriel</label>
                <input type="email" class="form-control" name="email" maxlength="128" id="email" v-model="email" v-on:input="resetControlsAndError"/>
            </div>
            <div class="form-group">
                <label for="password">Mot de passe</label>
                <input type="password" class="form-control" name="password" maxlength="50" id="password" v-model="password" v-on:input="resetControlsAndError"/>
                <small id="rulePassword" class="form-text text-muted">Plus de 5 caractères, au moins un chiffre et une ponctuation</small>
            </div>
            <div class="form-group">
                <label for="password">Répéter le mot de passe</label>
                <input type="password" class="form-control" name="passwordBis" maxlength="50" id="passwordBis" v-model="passwordBis" v-on:input="resetControlsAndError"/>
            </div>
        </div>
        <div class="col-12 col-lg-6 mb-3">
            <label for="map">Placer le marqueur ci-dessous pour situer votre adresse ou votre ville. Cela servira pour la carte des chaînes auxquelles vous contribuerez.</label>
            <Map @update-marker-position="updateMarkerPosition($event)" customClass="map320" initialZoom=5 :positionMarker="{longitude : longitude, latitude : latitude}"/>
        </div>
    </div>
    <div class="row" v-if="messageControl !== ''">
        <div class="col-12">
            <div class="alert alert-danger">
                {{messageControl}}
            </div>
        </div>
    </div>
    <div class="row" v-if="isCreateSuccessful" style="margin-top:15px;">
        <div class="col-12">
            <div class="alert alert-success">
                <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Votre compte a été créé. Vous allez recevoir un mail avec un code de validation à saisir lors de votre première connexion.
            </div>
        </div>
    </div>
    <div class="row" v-if="messageError !== ''" style="margin-top:15px;">
        <div class="col-12">
            <div class="alert alert-danger">
                {{messageError}}
            </div>
        </div>
    </div>
    <div class="row" v-if="!isCreateSuccessful">
        <div class="col-12">
            <button class='btn btn-primary' @click="createAccount"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isCreateAccountInProgress"/>Valider</button>
            <button class='btn btn-secondary' style="float: right;" @click="cancelCreateAccount">Retour</button>
        </div>
    </div>
</template>
<script>
    import Map from "@/components/Map.vue";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    library.add(faCheckCircle, faSpinner);
    export default {
        name: "CreateAccount",
        components: { Map },
        emits: ["manage-user-connected", "return-to-login"],
        data: () => ({
            isUsePseudonym: false,
            pseudonym:"",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordBis: "",
            isCreateSuccessful:false,
            messageError:"",
            messageControl: "",
            isMarkerMoved: false,
            longitude: undefined,
            latitude: undefined,
            isCreateAccountInProgress: false
        }),
        created() {
            var request = new Request("/api" + "/get_user_localisation", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            fetch(request).then(response => {
                response.json().then(data => {
                    if (response.ok) {
                        this.longitude = data.longitude;
                        this.latitude = data.latitude;
                    }else{
                        this.longitude = 0;
                        this.latitude = 0;
                    }
                }).catch(() => {
                    this.longitude = 0;
                    this.latitude = 0;
                });
            }).catch(() => {
                this.longitude = 0;
                this.latitude = 0;
            });
        },
        methods: {
            updateMarkerPosition(event){
                this.resetControlsAndError();
                this.isMarkerMoved = true;
                this.longitude = event.latLng.lng().toFixed(6);
                this.latitude = event.latLng.lat().toFixed(6);
            },
            createAccount() {

                if(this.isCreateAccountInProgress){
                    return;
                }

                if(this.isUsePseudonym){
                    this.lastName = "";
                    this.firstName = "";
                }else{
                    this.pseudonym = "";
                }

                this.resetControlsAndError();

                if(this.firstName.length === 0 && !this.isUsePseudonym){
                    this.messageControl = "Veuillez renseigner votre prénom";
                    return;
                }
                if(this.firstName.length > 128 && !this.isUsePseudonym){
                    this.messageControl = "Le prénom doit comporter 128 caractères au maximum.";
                    return;
                }
                if(this.lastName.length === 0  && !this.isUsePseudonym){
                    this.messageControl = "Veuillez renseigner votre nom";
                    return;
                }
                if(this.lastName.length > 128 && !this.isUsePseudonym){
                    this.messageControl = "Le nom doit comporter 128 caractères au maximum.";
                    return;
                }
                if(this.pseudonym.length === 0  && this.isUsePseudonym){
                    this.messageControl = "Veuillez renseigner votre pseudonyme";
                    return;
                }
                if(this.pseudonym.length > 128 && this.isUsePseudonym){
                    this.messageControl = "Le pseudonyme doit comporter 128 caractères au maximum.";
                    return;
                }
                if(this.email.length === 0){
                    this.messageControl = "Veuillez renseigner votre adresse mail";
                    return;
                }
                if(this.email.length > 128){
                    this.messageControl = "L'adresse mail doit comporter 128 caractères au maximum.";
                    return;
                }
                if(!this.validateEmail(this.email)){
                    this.messageControl = "Veuillez renseigner une adresse mail valide";
                    return;
                }
                if(this.password.length === 0){
                    this.messageControl = "Veuillez renseigner un mot de passe";
                    return;
                }
                if(this.password.length > 50){
                    this.messageControl = "Le mot de passe doit comporter 50 caractères au maximum.";
                    return;
                }
                if(this.password.length < 6){
                    this.messageControl = "Veuillez renseigner un mot de passe avec plus de 5 caractères";
                    return;
                }
                if(!this.password.match(/\d/gi)){
                    this.messageControl = "Le mot de passe doit comporter au moins un chiffre";
                    return;
                }
                if(!this.password.match(/[_²&"'(-)=^$¤*!:;,+\\*/~#{[|`^@\]}¨£%§.?°<>]/gi)){
                    this.messageControl = "Le mot de passe doit comporter au moins un caractère spécial, comme une ponctuation";
                    return;
                }
                if(this.passwordBis.length === 0){
                    this.messageControl = "Veuillez répéter le mot de passe";
                    return;
                }
                if(this.password !== this.passwordBis){
                    this.messageControl = "Le second mot de passe est différent du premier";
                    return;
                }
                if(!this.isMarkerMoved){
                    this.messageControl = "Veuillez déplacer le marqueur de la carte sur votre adresse ou sur votre ville";
                    return;
                }
                this.isCreateAccountInProgress = true;

                var parameters = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    pseudonym: this.pseudonym,
                    email: this.email,
                    longitude: this.longitude,
                    latitude: this.latitude,
                    password: this.password,
                    passwordBis: this.passwordBis,
                    language: "fr"
                };

                var request = new Request("/api" + "/create_user", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            this.isCreateSuccessful = true;
                            setTimeout(function () {
                                this.pseudonym = "";
                                this.firstName = "";
                                this.lastName = "";
                                this.email = "";
                                this.password = "";
                                this.passwordBis = "";
                                this.longitude = 0;
                                this.latitude = 0;
                                this.resetControlsAndError();

                                this.$emit("return-to-login");
                            }.bind(this), 5000);
                        } else {
                            this.messageError = data.message;
                        }
                        this.isCreateAccountInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isCreateAccountInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isCreateAccountInProgress = false;
                });
            },
            cancelCreateAccount(){
                this.$emit("return-to-login");
            },
            resetControlsAndError(){
                this.isCreateSuccessful = false;
                this.messageError= "";
                this.messageControl = "";
            },
            validateEmail(email) {
                const result = /\S+@\S+\.\S+/;
                return result.test(email);
            }
        }
    }
</script>
<style>
    .map320{
        height: 320px;
    }
</style>