<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <a class="navbar-brand" href="#" @click="redirect('Home')">BookChain</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" v-if="user !== undefined">
                    <a class="nav-link" href="#" @click="redirect('DetailUser', {idUser:user.id})">Votre espace</a>
                </li>
                <li v-if="numberLinksToValidate == 1">
                    <a class="nav-link" href="#" @click="redirect('LinksToValidate')"><span class="text-circle">{{numberLinksToValidate}}</span> maillon à valider</a>
                </li>
                <li v-if="numberLinksToValidate > 1">
                    <a class="nav-link" href="#" @click="redirect('LinksToValidate')"><span class="text-circle">{{numberLinksToValidate}}</span> maillons à valider</a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" v-if="user === undefined">
                    <a class="nav-link" data-toggle="modal" data-target="#modalLogin" href="#">Se connecter</a>
                </li>
                <li class="nav-item dropdown" v-if="user !== undefined">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{user.pseudonym}}{{user.firstName}} {{user.lastName}}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="#" @click="redirect('UpdateAccount')">Vos propriétés</a>
                        <a class="dropdown-item" href="#" @click="redirect('UpdatePassword')">Changer votre mot de passe</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#" @click="logout">Se déconnecter</a>
                    </div>
                </li>
                <!--<li class="nav-item">
                    <a class="nav-link" href="#">FR</a>
                </li>-->
            </ul>
        </div>
    </nav>
    <Login @manage-user-connected="manageUserConnected($event)"/>
</template>

<script>
    import Login from "@/components/Login.vue";
    import utils from "@/utils/utils.js";

    export default {
        name: "Header",
        components: { Login },
        emits: ["manage-user-connected"],
        props: {
            user: {
                type: Object
            },
            isResetLinksToValidate: Boolean
        },
        data() {
            return {
                numberLinksToValidate: 0
            };
        },
        watch:{
            isResetLinksToValidate(){
                this.getLinksToValidate();
            }
        },
        created() {
            var jwt = utils.getCookie('jwt');

            var request = new Request("/api" + "/validate_token", {
                method: "POST",
                body: JSON.stringify({ jwt:jwt }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            fetch(request).then(response => {
                response.json().then(data => {
                    if (response.ok && data.data != undefined) {
                        this.$emit("manage-user-connected", data.data);
                    }else{
                        // remove jwt
                        utils.setCookie("jwt", "", 1);
                        this.$emit("manage-user-connected", undefined);
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }).catch((error) => {
                console.error(error);
            });
            this.getLinksToValidate();
        },
        methods: {
            logout(){
                // remove jwt
                utils.setCookie("jwt", "", 1);
                this.$emit("manage-user-connected", undefined);
                if(this.$route.name === "UpdateAccount" || this.$route.name === "UpdatePassword" ||  this.$route.name === "LinksToValidate"){
                    this.$router.push({
                        name: "Home"
                    });
                }
            },
            redirect(nextComposant, params){
                this.$router.push({
                    name: nextComposant,
                    params:params
                });
            },
            manageUserConnected(user){
                this.$emit("manage-user-connected", user);
                this.getLinksToValidate();
            },
            getLinksToValidate(){
                this.numberLinksToValidate = 0;
                var jwt = utils.getCookie('jwt');

                var request = new Request("/api" + "/get_links_to_validate", {
                    method: "POST",
                    body: JSON.stringify({ jwt:jwt, timeZoneOffset:new Date().getTimezoneOffset() }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    if (response.ok) {
                        response.json().then(data => {
                            this.numberLinksToValidate = data.linksToValidate.length;
                        });
                    }
                })
            }
        }
    } 
</script>
<style>
    .text-circle{
        border-radius: 50%;
        width: 34px;
        border: 4px solid #9b0000;
        text-align: center;
        display: inline-block;
        margin-top: -4px;
        margin-bottom: -4px;
    }
</style>