<template>
    <div class="container" v-if="isbn !== ''">
        <div class="row">
            <div class="col">
                <h4 class="title">
                    <span>
                        Chaîne N°<b>{{this.$route.params.codeChain}}</b>
                    </span>
                </h4>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-5">
                <div class="row">
                    <div class="col-5 col-sm-6 col-md-5">
                        <img :src="urlImage" @error="setNoBookCover" style="width:100%;"/>
                    </div>

                    <div class="col-7 col-sm-6 col-md-7" >
                        <div class="row" v-if="title !== ''">
                            <div class="col-12">
                                <b>Titre</b> : {{title}}
                            </div>
                        </div>
                        <div class="row" v-if="authors !== ''">
                            <div class="col-12">
                                <b>Auteur(s)</b> : {{authors}}
                            </div>
                        </div>
                        <div class="row" v-if="publishers !== ''">
                            <div class="col-12">
                                <b>Editeur(s)</b> : {{publishers}}
                            </div>
                        </div>
                        <div class="row" v-if="publishedDate !== ''">
                            <div class="col-12">
                                <b>Date de publication</b> : {{publishedDate}}
                            </div>
                        </div>
                        <div class="row" v-if="isbn !== ''">
                            <div class="col-12">
                                <b>ISBN</b> : {{isbn}}
                            </div>
                        </div>
                        <div class="row" v-if="distance !== ''">
                            <div class="col-12">
                                <b>Distance totale de la chaîne</b> : {{distance}} Km
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <a :href="'https://twitter.com/intent/tweet?text=La%20chaîne%20sur%20%22' + encodeURIComponent(title) + '%22%20est%20sur%20BookChain%20%3A&url=https%3A%2F%2Fwww.bookchain.fr%2Fchain%2F' + this.$route.params.codeChain + '&via=BookChain_fr'" class="socialMediaButton" style="background: #55ACEE;color: white;margin-bottom: -10px;" target="_blank" rel="nofollow noopener"><font-awesome-icon :icon="['fab', 'twitter']"/></a>
                                <a :href="'https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwww.bookchain.fr%2Fchain%2F' + this.$route.params.codeChain + '&t=La%20chaîne%20sur%20%22' + encodeURIComponent(title) + '%22%20est%20sur%20BookChain%20%3A'" class="socialMediaButton mr-3" style="background: #3B5998;color: white;margin-bottom: -10px;" target="_blank" rel="nofollow noopener"><font-awesome-icon :icon="['fab', 'facebook']"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top:25px;margin-bottom:25px;">
                    <div class="row" v-if="user !== undefined && isUserDefinitiveLink">
                        <did class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea class="form-control" rows="6" maxlength="1000" id="comment" v-model="comment" v-on:input="resetControlsAndError" placeholder="Contribuez à écrire l'histoire de la chaîne en indiquant, par exemple, dans quel contexte vous avez reçu ce livre, ce que vous en avez pensé, un clin d'oeil au prochain maillon, etc. &#10;Veillez à saisir un texte modéré, sans jugement sur des personnes."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="modifySuccessful">
                                <div class="col-12">
                                    <div class="alert alert-success" >
                                        <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Votre message a été enregistré.
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <button class='btn btn-primary' style="float: right;" @click="updateComment"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isUpdateCommentInProgress"/>Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                        </did>
                    </div>
                    <div v-else-if="linksDetail !== undefined && linksDetail.length > 0 && linksDetail[linksDetail.length-1].isInValidation && isUserTemporaryLink">
                        <div class="row" >
                            <div class="col-12">
                                Votre attribution est en cours de validation. 
                                <button class='btn btn-danger mb-3' style="float: right;margin-top:10px;margin-bottom:" data-toggle="modal" data-target="#modalDeleteLink"><font-awesome-icon :icon="['far', 'trash-alt']" class="mr-2"/>Annuler l'attribution ?</button>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="linksDetail !== undefined && linksDetail.length > 0 && linksDetail[linksDetail.length-1].isInValidation && !isUserTemporaryLink">
                        <div class="row" >
                            <div class="col-12">
                                L'attribution du dernier maillon est en cours de validation. Il n'est pas encore possible de l'attribuer à quelqu'un d'autre.
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="user === undefined">
                        <div class="col-12">
                            <button class='btn btn-primary' data-toggle="modal" data-target="#modalLogin">Connectez-vous pour vous déclarer comme prochain maillon de cette chaîne</button>
                        </div>
                    </div>

                    <div class="row" v-else-if="user !== undefined && !isUserDefinitiveLink && !isUserTemporaryLink && !isCreateLinkSuccess">
                        <div class="col-12" style="text-align: center;">
                            <button class='btn btn-primary' @click="createLink" style="margin-top: 10px;"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isCreateLinkInProgress"/>Se déclarer comme le prochain maillon</button>
                        </div>
                    </div>
                    <div class="row" v-if="isCreateLinkSuccess">
                        <div class="col-12">
                            <div class="alert alert-success">
                                <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/><strong>Vous avez été ajouté comme maillon temporaire.</strong> Un mail vient d'être envoyé au maillon précédent afin de valider cette action. En l'absence de réponse de sa part sous une semaine, votre maillon sera déclaré définitif. Bonne lecture !
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="messageControl !== ''">
                        <div class="col-12 alert alert-danger">
                            {{messageControl}}
                        </div>
                    </div>
                    <div class="row" style="margin-top:15px" v-if="messageError !== ''">
                        <div class="col-12">
                            <div class="alert alert-danger">
                                {{messageError}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12 col-lg-7" style="margin-bottom:15px">
                <Map :initialZoom=1 customClass="map450" :linksDetail="linksDetail" :flagResetLinks="flagResetLinks"/>
            </div>
        </div>
    </div>
    <div class="container" v-if="isChainUnknow">
        <div class="row">
            <div class="col-12 my-auto alert alert-danger">
                <h4 style="text-align: center;">
                    La chaîne <b>{{this.$route.params.codeChain}}</b> est introuvable.
                </h4>
            </div>
        </div>
    </div>

    <SearchModule :user="user" :initialCodeChain="this.$route.params.codeChain"/>
    
    <div class="modal fade" id="modalDeleteLink" tabindex="-1" role="dialog" v-if="linksDetail !== undefined && linksDetail.length > 0 && linksDetail[linksDetail.length-1].isInValidation && isUserTemporaryLink">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Suppression de votre maillon temporaire</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalDeleteLink">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <p>
                                Voulez-vous vraiment annuler votre demande d'attribution ? Cela va supprimer votre maillon temporaire.
                            </p>
                        </div>
                    </div>
                    <div class="row" style="margin-top:15px" v-if="isDeleteLinkSuccessful">
                        <div class="col-12">
                            <div class="alert alert-success" >
                                <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Votre maillon a été supprimé avec succès.
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top:15px" v-if="messageErrorDeleteLink !== ''">
                        <div class="col-12">
                            <div class="alert alert-danger">
                                {{messageErrorDeleteLink}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="!isDeleteLinkSuccessful">
                    <button type="button" class="btn btn-danger" @click="deleteLink"><font-awesome-icon :icon="['far', 'trash-alt']" v-if="!isDeleteLinkInProgress" class="mr-2"/><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isDeleteLinkInProgress"/>Confirmer la suppression</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Map from "@/components/Map.vue";
    import SearchModule from "@/components/SearchModule.vue";
    import utils from "@/utils/utils.js";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { faTrashAlt, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
    import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons'
    library.add(faExclamationTriangle, faTrashAlt, faCheckCircle, faTwitter, faFacebook, faSpinner);
    export default {
        name: "DetailChain",
        components: { Map, SearchModule },
        emits: ["manage-user-connected"],
        props: {
            user: Object
        },
        data: () => ({
            noBookCover: require('@/assets/images/no_book_cover.png'),
            isbn : "",
            title : "",
            authors: "",
            publishers : "",
            publishedDate : "",
            distance: "",
            urlImage : "",
            linksDetail: undefined,
            flagResetLinks: false,
            isCreateLinkSuccess: false,
            isDeleteLinkSuccessful: false,
            isChainUnknow: false,
            isUserDefinitiveLink: false,
            isUserTemporaryLink: false,
            comment: "",
            modifySuccessful: false,
            messageError: "",
            messageControl: "",
            messageErrorDeleteLink: "",
            isUpdateCommentInProgress: false,
            isCreateLinkInProgress: false,
            isDeleteLinkInProgress: false
        }),
        watch:{
            user(){
                this.resetControlsAndError();
                this.updateUserData();
            },
            '$route.params.codeChain'(){
                this.searchChain();
            }
        },
        created() {
            this.searchChain();
        },
        methods:{
            searchChain(){
                if(this.$route.params.codeChain === undefined){
                    return;
                }
                this.isbn = "";
                this.title = "";
                this.authors = "";
                this.publishers = "";
                this.publishedDate = "";
                this.urlImage = "";
                this.linksDetail = undefined;
                this.flagResetLinks=!this.flagResetLinks;
                this.isCreateLinkSuccess = false;
                this.isChainUnknow = false;
                this.isUserDefinitiveLink = false;
                this.isUserTemporaryLink = false,
                this.comment = "";

                var request = new Request("/api" + "/chain/" + this.$route.params.codeChain, {
                    method: "POST",
                    body: JSON.stringify({timeZoneOffset:new Date().getTimezoneOffset()}),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    if (response.ok) {
                        return response.json().then(data => {
                            this.isbn = data.book.isbn;
                            this.title = data.book.title;
                            this.authors = data.book.authors;
                            this.publishers = data.book.publishers;
                            this.publishedDate = data.book.publishedDate;
                            //this.urlImage = "https://images.isbndb.com/covers/" + data.book.isbn.substring(data.book.isbn.length-4, data.book.isbn.length-2) +"/" + data.book.isbn.substring(data.book.isbn.length-2) + "/" + data.book.isbn + ".jpg";
                            this.urlImage = "https://pictures.abebooks.com/isbn/"+ data.book.isbn + "-us-300.jpg";
                            this.linksDetail = data.linksDetail;
                            this.distance = data.distance;
                            this.updateUserData();
                        }).catch((error) => {
                            console.error(error);
                            this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        });
                    } else {
                        this.isChainUnknow = true;
                    }
                }).catch((error) => {
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    console.error(error);
                });
            },
            createLink(){
                if(this.isCreateLinkInProgress){
                    return;
                }
                this.isCreateLinkInProgress = true;

                var jwt = utils.getCookie('jwt');

                var request = new Request("/api" + "/create_link", {
                    method: "POST",
                    body: JSON.stringify({ jwt: jwt, codeChain: this.$route.params.codeChain, timeZoneOffset:new Date().getTimezoneOffset()}),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            this.linksDetail.push(data.detailLink);
                            this.distance = data.distance;
                            this.flagResetLinks=!this.flagResetLinks;
                            this.isUserTemporaryLink = true;
                            this.isCreateLinkSuccess = true;
                        }else{
                            if(response.status === 401){
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                                this.$emit("manage-user-connected", undefined);
                            }
                            this.messageError = data.message;
                        }
                        this.isCreateLinkInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isCreateLinkInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isCreateLinkInProgress = false;
                });
            },
            setNoBookCover() { 
                this.urlImage = this.noBookCover;
            },
            updateComment(){
                
                if(this.isUpdateCommentInProgress){
                    return;
                }

                this.resetControlsAndError();

                if(this.comment.length > 1000){
                    this.messageControl = "Le commentaire doit comporter 1000 caractères au maximum.";
                }

                this.isUpdateCommentInProgress = true;

                var parameters = {
                    jwt:utils.getCookie('jwt'),
                    codeChain: this.$route.params.codeChain,
                    comment: this.comment
                };

                var request = new Request("/api" + "/update_link_comment", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    
                    response.json().then(data => {
                        if (response.ok) {
                            this.modifySuccessful = true;
                            //Refresh the comment on the map
                            for(var index in this.linksDetail){
                                var link = this.linksDetail[index];

                                if(link.idUser === this.user.id){
                                    link.comment = this.comment;
                                }
                            }
                            this.flagResetLinks=!this.flagResetLinks;
                            setTimeout(function () {
                                this.modifySuccessful = false;
                            }.bind(this), 5000);
                        }else{
                            if(response.status === 401){
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                                this.$emit("manage-user-connected", undefined);
                            }
                            this.messageError = data.message;
                        }
                        this.isUpdateCommentInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isUpdateCommentInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isUpdateCommentInProgress = false;
                });
            },
            resetControlsAndError(){
                this.modifySuccessful = false;
                this.isCreateLinkSuccess = false;
                this.isDeleteLinkSuccessful = false;
                this.messageError = "";
                this.messageControl = "";
            },
            updateUserData(){
                this.isUserDefinitiveLink = false;
                this.isUserTemporaryLink = false;
                if(this.user != undefined){
                    for(var index in this.linksDetail){
                        var link = this.linksDetail[index];

                        if(link.idUser === this.user.id){
                            this.comment = link.comment;
                            if(link.isInValidation){
                                this.isUserTemporaryLink = true;
                            }else{
                                this.isUserDefinitiveLink = true;
                            }
                            break;
                        }
                    }
                }
            },
            deleteLink(){
                if(this.isDeleteLinkInProgress){
                    return;
                }
                this.isDeleteLinkInProgress = true;

                var jwt = utils.getCookie('jwt');

                var request = new Request("/api" + "/delete_link", {
                    method: "POST",
                    body: JSON.stringify({ jwt: jwt, codeChain: this.$route.params.codeChain}),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            this.isDeleteLinkSuccessful = true;
                            setTimeout(function () {
                                //Close the modal
                                this.$refs.closeModalDeleteLink.click();
                                this.linksDetail.pop();
                                this.flagResetLinks=!this.flagResetLinks;
                                this.isUserTemporaryLink = false;
                                this.resetControlsAndError();
                            }.bind(this), 2000);
                        }else{
                            if(response.status === 401){
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                                this.$emit("manage-user-connected", undefined);
                            }
                            this.messageErrorDeleteLink = data.message;
                        }
                        this.isDeleteLinkInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageErrorDeleteLink = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isDeleteLinkInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageErrorDeleteLink = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isDeleteLinkInProgress = false;
                });
            }
        }
    }
</script>
<style>
    .map450{
        height: 450px;
    }
</style>