<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h4 class="title">
                    <span>
                        Modification du mot de passe
                    </span>
                </h4>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="password">Mot de passe actuel</label>
                    <input type="password" class="form-control" name="passwordOld" maxlength="50" id="passwordOld" v-model="passwordOld" v-on:input="resetControlsAndError"/>
                </div>
                <div class="form-group">
                    <label for="password">Nouveau mot de passe</label>
                    <input type="password" class="form-control" name="passwordNew" maxlength="50" id="passwordNew" v-model="passwordNew" v-on:input="resetControlsAndError"/>
                    <small id="rulePassword" class="form-text text-muted">Plus de 5 caractères, au moins un chiffre et une ponctuation</small>
                </div>
                <div class="form-group">
                    <label for="password">Répéter le nouveau mot de passe</label>
                    <input type="password" class="form-control" name="passwordNewBis" maxlength="50" id="passwordNewBis" v-model="passwordNewBis" v-on:input="resetControlsAndError" v-on:keyup.enter="updatePassword()"/>
                </div>
            </div>
        </div>
        <div class="row" v-if="messageControl !== ''">
            <div class="col-12">
                <div class="alert alert-danger">
                    {{messageControl}}
                </div>
            </div>
        </div>
        <div class="row" v-if="modifySuccessful">
            <div class="col-12">
                <div class="alert alert-success" >
                    <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Votre mot de passe a été modifié.
                </div>
            </div>
        </div>
        <div class="row" v-if="messageError !== ''">
            <div class="col-12">
                <div class="alert alert-danger">
                    {{messageError}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                    <button class='btn btn-primary' @click="updatePassword"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isUpdatePasswordInProgress"/>Valider</button>
            </div>
        </div>
    </div>
</template>
<script>
    import utils from "@/utils/utils.js";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
    library.add(faCheckCircle, faSpinner);
    export default {
        name: "UpdatePassword",
        emits: ["manage-user-connected"],
        props: {
            user: Object
        },
        data() {
            return {
                passwordOld: "",
                passwordNew: "",
                passwordNewBis: "",
                modifySuccessful:false,
                messageError:"",
                messageControl: "",
                isUpdatePasswordInProgress: false
            };
        },
        watch:{
            user(){
                this.resetControlsAndError();

                if(this.user === undefined){
                    this.messageError = "Identifiez-vous pour réaliser cette action.";
                }
            }
        },
        created() {
            this.initializeUser();
        },
        methods: {
            initializeUser(){
                this.resetControlsAndError();

                var jwt = utils.getCookie('jwt');

                var request = new Request("/api" + "/validate_token", {
                    method: "POST",
                    body: JSON.stringify({ jwt:jwt }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data => {
                        if (response.ok) {
                            if(data.data != undefined){
                                this.$emit("manage-user-connected", data.data);
                            }else{
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                                this.$emit("manage-user-connected", undefined);
                                this.messageError = "Veuillez vous connecter pour réaliser cette action";
                            }
                        }else{
                            if(response.status === 401){
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                                this.$emit("manage-user-connected", undefined);
                            }
                            this.messageError = "Veuillez vous connecter pour réaliser cette action";
                        }
                    }).catch((error) => {
                        console.error(error);
                        this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                });
            },
            updatePassword() {

                if(this.isUpdatePasswordInProgress){
                    return;
                }

                this.resetControlsAndError();

                if(this.passwordOld.length === 0){
                    this.messageControl = "Veuillez renseigner le mot de passe actuel";
                    return;
                }
                if(this.passwordNew.length === 0){
                    this.messageControl = "Veuillez renseigner le nouveau mot de passe";
                    return;
                }
                if(this.passwordNewBis.length === 0){
                    this.messageControl = "Veuillez répéter le nouveau mot de passe";
                    return;
                }
                if(this.passwordNew !== this.passwordNewBis){
                    this.messageControl = "Le second mot de passe est différent du premier";
                    return;
                }
                if(this.passwordNew.length < 6){
                    this.messageControl = "Veuillez renseigner un nouveau mot de passe avec plus de 5 caractères";
                    return;
                }
                if(this.passwordNew.length > 50){
                    this.messageControl = "Le nouveau mot de passe doit comporter 50 caractères au maximum.";
                    return;
                }
                if(!this.passwordNew.match(/\d/gi)){
                    this.messageControl = "Le nouveau mot de passe doit comporter au moins un chiffre";
                    return;
                }
                if(!this.passwordNew.match(/[_²&"'(-)=^$¤*!:;,+\\*/~#{[|`^@\]}¨£%§.?°<>]/gi)){
                    this.messageControl = "Le mot de passe doit comporter au moins un caractère spécial, comme une ponctuation";
                    return;
                }

                this.isUpdatePasswordInProgress = true;

                var parameters = {
                    jwt:utils.getCookie('jwt'),
                    passwordOld: this.passwordOld,
                    passwordNew: this.passwordNew,
                    passwordNewBis: this.passwordNewBis,
                };
                var request = new Request("/api" + "/update_password", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                    'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            utils.setCookie("jwt", data.jwt, 1);
                            this.modifySuccessful = true;
                            setTimeout(function () {
                                this.modifySuccessful = false;
                            }.bind(this), 5000);
                        }else{
                            if(response.status === 401){
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                                this.$emit("manage-user-connected", undefined);
                            }
                            this.messageError = data.message;
                        }
                        this.isUpdatePasswordInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isUpdatePasswordInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isUpdatePasswordInProgress = false;
                });
            },
            resetControlsAndError(){
                this.modifySuccessful = false;
                this.messageError= "";
                this.messageControl = "";
            }
        }
    } 
</script>