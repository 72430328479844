<template>
    <div class="container" v-if="userProfile !== undefined">
        <div class="row">
            <div class="col-12">
                <h4 class="title">
                    <span>
                        {{displayableUser}}
                    </span>
                </h4>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-12">
                <a :href="'https://twitter.com/intent/tweet?text=Le%20Profil%20de%20' + encodeURIComponent(displayableUser) + '%20est%20sur%20BookChain&url=https%3A%2F%2Fwww.bookchain.fr%2Fuser%2F' + this.$route.params.idUser + '&via=BookChain_fr'" class="socialMediaButton" style="background: #55ACEE;color: white;margin-top: -10px;" target="_blank" rel="nofollow noopener"><font-awesome-icon :icon="['fab', 'twitter']"/></a>
                <a :href="'https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwww.bookchain.fr%2Fuser%2F' + this.$route.params.idUser + '&t=Le%20Profil%20de%20' + encodeURIComponent(displayableUser) + '%20est%20sur%20BookChain'" class="socialMediaButton mr-3" style="background: #3B5998;color: white;margin-top: -10px;" target="_blank" rel="nofollow noopener"><font-awesome-icon :icon="['fab', 'facebook']"/></a>
                Profil créé le {{userProfile.created}}
            </div>
        </div>

        <div class="row" v-if="createdChains !== undefined && createdChains.length > 0">
            <div class="col-12">
                <h6 class="sub-title">
                    <span>
                        Liste des chaînes créées
                    </span>
                </h6>
            </div>
        </div>
        <div class="row" v-if="createdChains !== undefined && createdChains.length > 0">
            <div class="col-6 col-md-4 col-lg-3 col-xl-2" v-for="chain in createdChains" :key="chain">
                <div class="row">
                    <div class="col-12">
                        <img :src="chain.urlImage" @error="setNoBookCover(chain)" style="width:100%;"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b>Titre</b> : {{chain.title}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b>Auteur(s)</b> : {{chain.authors}}
                    </div>
                </div>
                <div class="row" style="margin-bottom:45px;">
                    <div class="col-12">
                        <router-link :to="{ name: 'DetailChain', params: { codeChain: chain.codeChain }}"><button class='btn btn-primary'>Chaîne : {{chain.codeChain}}</button></router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top:20px;" v-if="notCreatedChains !== undefined && notCreatedChains.length > 0">
            <div class="col-12">
                <h6 class="sub-title">
                    <span>
                        Liste des chaînes en tant que participant
                    </span>
                </h6>
            </div>
        </div>
        <div class="row" v-if="notCreatedChains !== undefined && notCreatedChains.length > 0">
            <div class="col-6 col-md-4 col-lg-3 col-xl-2" v-for="chain in notCreatedChains" :key="chain">
                <div class="row">
                    <div class="col-12">
                        <img :src="chain.urlImage" @error="setNoBookCover(chain)" style="width:100%;"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b>Titre</b> : {{chain.title}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b>Auteur(s)</b> : {{chain.authors}}
                    </div>
                </div>
                <div class="row" style="margin-bottom:45px;">
                    <div class="col-12">
                        <router-link :to="{ name: 'DetailChain', params: { codeChain: chain.codeChain }}"><button class='btn btn-primary'>Chaîne : {{chain.codeChain}}</button></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" v-if="messageError !== undefined && messageError !== ''">
        <div class="row">
            <div class="col-12 alert alert-danger">
                {{messageError}}
            </div>
        </div>
    </div>

    <SearchModule :user="user"/>
</template>

<script>
    import utils from "@/utils/utils.js";
    import SearchModule from "@/components/SearchModule.vue";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons'
    library.add(faTwitter, faFacebook);
    export default {
        name: "DetailUser",
        components: { SearchModule },
        props: {
            user: Object
        },
        data() {
            return {
                noBookCover: require('@/assets/images/no_book_cover.png'),
                userProfile: undefined,
                createdChains: undefined,
                notCreatedChains: undefined,
                messageError: ""
            };
        },
        computed: {
            displayableUser: function () {
                if(this.userProfile !== undefined){
                    if(this.userProfile.pseudonym !== undefined && this.userProfile.pseudonym != ''){
                        return this.userProfile.pseudonym;
                    }else{
                        return this.userProfile.firstName + " " + this.userProfile.lastName;
                    }
                }else{
                    return "";
                }
            },
            encodeURIUser: function () {
                if(this.userProfile !== undefined){
                    if(this.userProfile.pseudonym !== undefined && this.userProfile.pseudonym != ''){
                        return encodeURIComponent(this.userProfile.pseudonym);
                    }else{
                        return encodeURIComponent(this.userProfile.firstName + " " + this.userProfile.lastName);
                    }
                }else{
                    return "";
                }
            }
        },
        watch:{
            user(){
                this.searchUser();
            },
            '$route.params.idUser'(){
                this.searchUser();
            }
        },
        created() {
            this.searchUser();
        },
        methods:{
            searchUser(){
                this.messageError = "";
                this.userProfile = undefined;

                if(this.$route.params.idUser === undefined){
                    return;
                }

                var jwt = utils.getCookie('jwt');

                var request = new Request("/api" + "/user/" + this.$route.params.idUser, {
                    method: "POST",
                    body: JSON.stringify({ jwt:jwt, timeZoneOffset:new Date().getTimezoneOffset()}),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data => {
                        if (response.ok) {
                            this.userProfile = data.user;
                            this.createdChains = data.createdChains;
                            this.notCreatedChains = data.notCreatedChains;
                            for(var index in this.createdChains){
                                //this.createdChains[index].urlImage = 'https://images.isbndb.com/covers/' + this.createdChains[index].codeChain.substring(6, 8) + '/' + this.createdChains[index].codeChain.substring(8, 10) + '/97' + this.createdChains[index].codeChain.substring(0, 11) + '.jpg';
                                this.createdChains[index].urlImage = "https://pictures.abebooks.com/isbn/97"+ this.createdChains[index].codeChain.substring(0, 11) + "-us-300.jpg"
                            }
                            for(index in this.notCreatedChains){
                                //this.notCreatedChains[index].urlImage = 'https://images.isbndb.com/covers/' + this.notCreatedChains[index].codeChain.substring(6, 8) + '/' + this.notCreatedChains[index].codeChain.substring(8, 10) + '/97' + this.notCreatedChains[index].codeChain.substring(0, 11) + '.jpg';
                                this.notCreatedChains[index].urlImage = "https://pictures.abebooks.com/isbn/97"+ this.notCreatedChains[index].codeChain.substring(0, 11) + "-us-300.jpg"
                            }
                        }else{
                            this.messageError = data.message;
                        }
                    }).catch((error) => {
                        console.error(error);
                        this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                });
            },
            setNoBookCover(chain) {
                chain.urlImage = this.noBookCover;
            }
        }
    }
</script>