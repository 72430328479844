<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h4 class="title">
                    <span>
                        Données personnelles
                    </span>
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>Protection des données personnelles</h3>
                <p>
                    Nous sommes soucieux de la protection de vos données personnelles.
                    <br/>
                    Nous nous engageons à mettre en oeuvre le meilleur niveau de protection de vos données personnelles en conformité avec le réglement général sur la protection de données personnelles.
                    <br/>
                    Cette page regroupe l'ensemble des informations concernant le traitement de vos données personnelles.
                    <br/>
                    Pour toute information sur la protection des données personnelles, vous pouvez également consulter le site de la commission informatique et liberté (CNIL) https://www.cnil.fr/fr/reglement-europeen-protection-donnees/
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>Identité du responsable de traitement</h3>
                <p>
                    Nicolas Le Texier
                    <br/>
                    58 rue des Collinnes
                    <br/>
                    44300 Nantes
                    <br/>
                    France
                    <br/>
                    Adresse mail : contact@bookchain.fr
                    <br/>
                    site : www.bookchain.fr
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>Principes généraux</h3>
                <p>
                    Le principe de Bookchain amène à conserver des données qui vous sont personnelles.
                    <br/>
                    La conservation de vos données personnelles est mise en oeuvre dans le respect de différents principes.
                    <br/>
                    <b>Limitation des finalités</b> : les données ne sont collectées que pour des raisons et des finalités déterminées, nécessaires, explicites et légitimes. Vous trouverez sur cette page web la liste des traitements effectués.
                    <br/>
                    <b>Minimisation des données en fonction des finalités</b> : les données collectées sont limitées à ce qui est nécessaire et pertinent au regard des finalités pour lesquelles elles sont traitées. Nous ne vous demanderons pas des données qui seraient inutiles au service et à la qualité du service que nous vous devons.
                    <br/>
                    <b>Limitation de la conservation dans le temps</b> : Vos données personnelles ne seront conservées sous une forme permettant l'identification des personnes que le temps nécessaire à la poursuite de la finalité pour laquelle elles ont été collectées. Passée cette limite, vos données seront supprimées ou rendues anonymes. A chaque fois que possible, elles seront pseudonymisées.
                    <br/>
                    <b>Loyauté et transparence</b> : la mise en oeuvre des traitements de vos données est effectuée de manière loyale et transparente, notamment une information enrichie pour chacun des traitements listés ci-dessous. De même dans nos rapports avec vous nous, nous efforçons d'être le plus clairs possible
                    <br/>
                    <b>Exactitude</b> : Nous faisons en sorte que vos données soient exactes. De ce fait nous vous demanderons parfois de nous préciser d'éventuelles mises à jour si nécessaire.
                    <br/>
                    <b>Protection, intégrité et confidentialité</b> : les données à caractère personnel sont traitées de façon à garantir un niveau de sécurité adéquat. Des mesures techniques ou organisationnelles appropriées sont donc mises en place.
                    <br/>
                    <b>Information</b> : nous vous donnons accès au responsable de traitement en mesure de vous répondre directement.
                    <br/><br/>
                    Dans le respect de ces principes, le recueil et la conservation de vos données personnelles se fait selon certaines conditions :
                    <ul>
                        <li>Votre consentement pour une ou plusieurs finalités. Par exemple, parce que vous avez consenti à recevoir les demandes de validations de maillons temporaires.</li>
                        <li>La bonne exécution contractuelle de nos services. Par exemple l'envoi d'un mail de confirmation de changement d'adresse mail et votre accès à votre historique de chaînes.</li>
                        <li>Le respect d'une obligation légale imposée par la législation de l'Union européenne ou d'un État membre.</li>
                        <li>L'intérêt légitime du responsable de traitement. Dans ce cas, il nous incombe de vous l'expliquer de façon simple et claire.</li>
                    </ul>
                    <br/>
                    <b>Protection</b> : votre mot de passe d'accès est encrypté.
                    <br/>
                    <b>Portabilité de vos données</b> : vous pouvez soit directement récupérer l'ensemble de vos données (Mon compte et Mon espace), soit nous demander de vous les envoyer.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>Dans le principe de bookchain, les finalités principales sont :</h3>
                <p>
                    <b>La création de votre compte pour accéder aux fonctionnalités du site</b>
                    Les informations et données personnelles vous concernant sont accessibles dans la page de votre compte.
                    <br/>
                    Les chaînes que vous avez créées ou auxquelles vous avez participées sont accessibles dans la page de votre espace
                </p>
                <p>
                    <b>L'envoi de mails</b>
                    Nous vous envoyons 3 types de mails :
                    <ul>
                        <li>Mail de confirmation de votre adresse mail : un code de validation est envoyé pour valider votre nouvelle adresse,</li>
                        <li>Mail de renouvellemnt de votre mot de passe : en cas d'oublie du mot de passe, un mot de passe temporaire est envoyé,</li>
                        <li>Mail de notification d'une demande de validation d'un maillon temporaire : lorsqu'un maillon temporaire est créé et que vous êtiez le dernier maillon, un mail de notification vous est envoyé pour vous inviter à vous connecter à bookchain.fr afin de valider ou invalider le maillon temporaire.</li>
                    </ul>
                </p>
                <p>
                    <b>Anonymisation ou Pseudonymisation de vos données :</b>
                    Vos mots de passe sont encryptés.
                    <br/>
                    Vous trouverez ci-dessous une description des finalités principales concernant l'utilisation de vos données, puis une liste détaillée de chaque traitement spécifique et des coordonnées éventuelles du sous-traitant que nous avons choisi pour vous apporter le meilleur service.
                    <br/>
                    Nous en limitons l'usage en qualité et en quantité,
                    <br/>
                    Bookchain vous propose d'utiliser un pseudonyme en remplacement de votre nom et prénom. De même pour vos coordonnées géographiques, vous avez la possibilité de situer votre emplacement à grosse maille : sur votre quartier, ville, ...

                </p>
                <p>
                    <b>Suppression de vos données</b>
                    Conformément à la loi informatique et libertés du 6 janvier 1978, vous disposiez déjà des droits d'accès, de rectification, de suppression et d'opposition prévus par la loi du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
                    <br/>
                    Depuis votre page de gestion de compte, un bouton de suppression vous permet de supprimer votre compte et les chaînes que vous avez créées dont vous êtes le seul maillon.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>Les données personnelles collectées</h3>
                <p>
                    <b>Quelles données ?</b>
                    Nous collectons et traitons notamment vos nom, prénom (ou pseudonyme), adresse email, mot de passe, longitude, latitude, adresse IP, date de création du compte, date de dernière connexion, date de dernier envoi d'un mail de notification de création d'un maillon temporaire, historique de vos maillons.
                </p>
                <p>
                    <b>Quand ?</b>
                    Nous collectons les informations que vous nous fournissez notamment quand :
                    <ul>
                        <li>
                            Vous créez votre compte,
                        </li>
                        <li>
                            Vous mettez à jour votre compte,
                        </li>
                        <li>
                            Vous participez à une chaîne.
                        </li>
                    </ul>
                </p>
                <p>
                    <b>Destinataires des données</b>
                    Les données collectées sur le site ne sont pas communiquées à des tiers.
                </p>
            </div>
        </div>
    </div>
</template>
