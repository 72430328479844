<template>
    <div style="color:whitesmoke;text-align: center;">
    <!--<div>-->
        <div class="row">
            <div class="col-12">
                <h4>Créer une chaîne </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                Saisissez le code-barres <font-awesome-icon icon="barcode" style="font-size:18px;"/> du livre<font-awesome-icon data-toggle="modal" data-target="#modalsbnPresentation" :icon="['far', 'question-circle']" v-on:input="resetControlsAndError" class="ml-2" style="cursor:pointer;"/>
            </div>
        </div>

        <div class="row">
            <div class="col-12 input-group mb-2">
                <div style="margin: auto;display: inherit;">
                    <input type="text" class="form-control" name="isbn" id="isbn" maxlength="17" placeholder="97..." style="max-width:200px;" v-model="isbn" v-on:keyup.enter="this.$refs.buttonSearchIsbn.click();"/>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" ref="buttonSearchIsbn" data-toggle="modal" data-target="#modalSearhBookResult" style="color: whitesmoke;" @click="searchBook"><font-awesome-icon icon="search"/></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modalsbnPresentation" ref="modalsbnPresentation" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Qu'est-ce que le code-barres/numéro ISBN ?</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalsbnPresentation">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 col-lg-5">
                            <p>Le numéro sous le code-barre du livre, commençant par 978 ou 979, est appelé un numéro ISBN. Ce numéro permet d'identifier de manière unique chaque édition de chaque livre.</p>
                            <p>Si le livre ne possède pas de code-barre, saisir le numéro ISBN généralement présent :
                                <ul>
                                    <li>Sur la couverture,</li>
                                    <li>Ou dans les premières pages,</li>
                                    <li>Ou dans les dernières pages,</li>
                                </ul>
                            </p>
                        </div>
                        <div class="col-12 col-lg-7">
                            <img src="@/assets/images/isbn_13_information.jpg" style="width:100%;margin-bottom:10px;"/>
                            <img src="@/assets/images/isbn_10_information.jpg" style="width:100%;"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modalSearhBookResult" tabindex="-1" role="dialog" style="z-index:1041;">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Livre associé au numéro {{isbn}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalSearhBookResult">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" v-if="!isIsbnUnknow">
                        <div class="col-5">
                            <img :src="urlImage" @error="setNoBookCover" style="width:100%;"/>
                        </div>

                        <div class="col-7" >
                            <div class="row" v-if="title !== ''">
                                <div class="col-12">
                                    <b>Titre</b> : {{title}}
                                </div>
                            </div>
                            <div class="row" v-if="authors !== ''">
                                <div class="col-12">
                                    <b>Auteur(s)</b> : {{authors}}
                                </div>
                            </div>
                            <div class="row" v-if="publishers !== ''">
                                <div class="col-12">
                                    <b>Editeur(s)</b> : {{publishers}}
                                </div>
                            </div>
                            <div class="row" v-if="publishedDate !== ''">
                                <div class="col-12">
                                    <b>Date de publication</b> : {{publishedDate}}
                                </div>
                            </div>
                            <div class="row" v-if="isbn13 !== ''">
                                <div class="col-12">
                                    <b>Numéro ISBN</b> : {{isbn13}}
                                </div>
                            </div>
                            <div class="row" v-if="isbn13 !== '' && messageError === ''" style="margin-top:20px;">
                                <div class="col-12">
                                    <button class='btn btn-primary' v-if="user === undefined" data-toggle="modal" data-target="#modalLogin">Connectez-vous pour créer la chaîne</button>
                                    <button class='btn btn-primary' v-if="user !== undefined" @click="createChain">Créer la chaîne</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    <div class="row" v-if="messageError !== ''" style="margin-top:15px;">
                        <div class="col-12 alert alert-danger">
                            {{messageError}}
                        </div>
                    </div>

                    <div class="row" v-if="isIsbnUnknow">
                        <div class="col-12" style="text-align:center;">
                            Ce numéro est inconnu.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading :loading="loading"/>
</template>
<script>
    import { library } from '@fortawesome/fontawesome-svg-core'
    import utils from "@/utils/utils.js";
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
    import { faSearch, faBarcode } from '@fortawesome/free-solid-svg-icons'
    import Loading from "@/components/Loading.vue";
    library.add(faQuestionCircle, faSearch, faBarcode);
    export default {
        name: "SearchIsbn",
        components: {Loading},
        props: {
            user: Object
        },
        data() {
            return {
                loading: false,
                noBookCover: require('@/assets/images/no_book_cover.png'),
                idBook:"",
                isbn: "",
                isbn13: "",
                title: "",
                authors: "",
                publishers: "",
                publishedDate: "",
                urlImage: "",
                messageError: "",
                isIsbnUnknow: false
            };
        },
        methods: {
            searchBook() {
                this.loading = true;
                this.resetControlsAndError();
                this.title = "";
                this.authors = "";
                this.publishers = "";
                this.publishedDate = "";
                this.urlImage = "";

                var request = new Request("/api" + "/search/" + this.isbn.replace(/[^0-9a-zA-Z]/gi, ''), {
                    method: "GET",
                    headers: {
                    'Content-Type': 'application/json'
                    }
                })
                fetch(request).then(response => {
                    
                    return response.json().then(data => {
                        if (response.ok) {
                            if(Object.keys(data).length === 0 && data.constructor === Object){
                                this.isIsbnUnknow = true;
                            }else{
                                this.isIsbnUnknow = false;
                                this.title = data.title;
                                this.authors = data.authors;
                                this.publishers = data.publishers;
                                this.publishedDate = data.publishedDate;
                                this.isbn13 = data.isbn;
                                this.idBook = data.id;

                                if(this.isbn13.length > 3){
                                    this.urlImage = "https://pictures.abebooks.com/isbn/"+ this.isbn13 + "-us-300.jpg"
                                    //this.urlImage = "https://images.isbndb.com/covers/" + this.isbn13.substring(this.isbn13.length-4, this.isbn13.length-2) +"/" + this.isbn13.substring(this.isbn13.length-2) + "/" + this.isbn13 + ".jpg";
                                }
                            }
                        } else {
                            this.messageError=data.message;
                        }
                        this.loading = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.loading = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.loading = false;
                });
            },
            createChain(){

                var parameters = {
                    idBook: this.idBook,
                    jwt:utils.getCookie('jwt'),
                };

                var request = new Request("/api" + "/create_chain", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                    'Content-Type': 'application/json'
                    }
                })
                fetch(request).then(response => {
                    return response.json().then(data => {
                        if (response.ok) {
                            this.$refs.closeModalSearhBookResult.click();
                            this.$router.push({
                                name: 'CreateChain',
                                params: {codeChain:data}
                            });
                        }else{
                            this.messageError=data.message;
                        }
                    }).catch((error) => {
                        console.error(error);
                        this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                });
            },
            resetControlsAndError(){
                this.isIsbnUnknow = false;
                this.messageError= "";
            },
            setNoBookCover() { 
                this.urlImage = this.noBookCover;
            }
        }
    }
</script>
