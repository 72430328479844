<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h4 class="title">
                    <span>
                        Modification des propriétés du compte
                    </span>
                </h4>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label for="firstName">Utiliser un pseudonyme ?</label>
                    <input type="checkbox" class="ml-3" v-model="isUsePseudonym" v-on:input="resetControlsAndError"/>
                </div>
                <div class="form-group" v-if="isUsePseudonym">
                    <label for="pseudonym">Pseudonyme</label>
                    <input type="text" class="form-control" name="pseudonym" maxlength="128" id="pseudonym" v-model="pseudonym" v-on:input="resetControlsAndError"/>
                </div>
                <div class="form-group" v-if="!isUsePseudonym">
                    <label for="firstname">Prénom</label>
                    <input type="text" class="form-control" name="firstname" maxlength="128" id="firstname" v-model="firstName" v-on:input="resetControlsAndError"/>
                </div>
                <div class="form-group" v-if="!isUsePseudonym">
                    <label for="lastname">Nom</label>
                    <input type="text" class="form-control" name="lastname" maxlength="128" id="lastname" v-model="lastName" v-on:input="resetControlsAndError"/>
                </div>
                <div class="form-group">
                    <label for="email">Adresse mail</label>
                    <input type="email" class="form-control" name="email" maxlength="128" id="email" v-model="email" v-on:input="resetControlsAndError"/>
                </div>
                <div class="form-group">
                    <label for="isPrivateProfile">Rendre privé votre page utilisateur ?</label>
                    <input type="checkbox" class="ml-3" v-model="isPrivateProfile" v-on:input="resetControlsAndError"/>
                </div>
                <div class="form-group">
                    <label for="isDisallowNotificationMail">Désactiver les notifications par mail ?</label>
                    <input type="checkbox" class="ml-3" v-model="isDisallowNotificationMail" v-on:input="resetControlsAndError"/>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <label for="map">Placer le curseur ci-dessous pour situer votre adresse ou votre ville. Cela servira pour les cartes des chaînes auxquelles vous contribuerez.</label>
                <Map @update-marker-position="updateMarkerPosition($event)" customClass="map300" initialZoom=10 :positionMarker="{longitude : longitude, latitude : latitude}"/>
            </div>
        </div>
        <div class="row" v-if="messageControl !== ''">
            <div class="col-12">
                <div class="alert alert-danger">
                    {{messageControl}}
                </div>
            </div>
        </div>
        <div class="row" v-if="modifySuccessful">
            <div class="col-12">
                <div class="alert alert-success">
                    <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Les modifications de votre compte sont enregistrées.
                </div>
            </div>
        </div>
        <div class="row" v-if="modifySuccessfulAndEmailChanged">
            <div class="col-12">
                <div class="alert alert-success">
                    Les modifications de votre compte sont enregistrées. Pour prendre en compte votre nouvelle adresse mail, un code de validation vient de vous être envoyé. Veuillez le saisir lors de votre reconnexion.
                </div>
            </div>
        </div>
        <div class="row" v-if="messageError !== ''">
            <div class="col-12">
                <div class="alert alert-danger">
                    {{messageError}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class='btn btn-primary' @click="updateAccount"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isUpdateAccountInProgress"/>Valider</button>
                <button class='btn btn-danger' style="float: right;" data-toggle="modal" data-target="#modalDeleteUser"><font-awesome-icon :icon="['far', 'trash-alt']" class="mr-2"/>Supprimer le compte</button>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modalDeleteUser" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Suppression de votre compte</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalDeleteUser">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <p>Vos données personnelles vont être supprimées :
                                <ul>
                                    <li>Nom/Prénom ou pseudonyme,</li>
                                    <li>Adresse courriel,</li>
                                    <li>Mot de passe,</li>
                                    <li>Date de création du compte,</li>
                                    <li>Coordonnées géographiques courantes.</li>
                                </ul>
                            </p>
                            <p>Concernant les chaînes :
                                <ul>
                                    <li>Les chaînes que vous avez créées dont vous êtes le seul maillon seront supprimées,</li>
                                    <li>Vos commentaires en tant que maillon seront également supprimées,</li>
                                    <li>Seules les coordonnées géographiques des maillons persisteront, de façon anonyme.</li>
                                </ul>
                            </p>
                            <p>
                                Veuillez saisir votre mot de passe pour confirmer la suppression de votre compte :
                                <input type="password" class="form-control" name="password" id="password" style="max-width:300px" v-model="password" v-on:input="resetControlsAndErrorDeleteUser"/>
                            </p>
                        </div>
                    </div>
                    <div class="row" style="margin-top:15px" v-if="deleteSuccessful">
                        <div class="col-12">
                            <div class="alert alert-success" >
                                <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Votre compte a été supprimé.
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top:15px" v-if="messageErrorDeleteUser !== ''">
                        <div class="col-12">
                            <div class="alert alert-danger">
                                {{messageErrorDeleteUser}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" @click="deleteAccount"><font-awesome-icon :icon="['far', 'trash-alt']" class="mr-2" v-if="!isDeleteAccountInProgress"/><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isDeleteAccountInProgress"/>Confirmer la suppression</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import utils from "@/utils/utils.js";
    import Map from "@/components/Map.vue";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { faTrashAlt, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
    library.add(faTrashAlt, faCheckCircle, faSpinner);
    export default {
        name: "UpdateAccount",
        props: {
            user: Object
        },
        components: { Map },
        emits: ["manage-user-connected"],
        data() {
            return {
                isUsePseudonym: false,
                pseudonym:"",
                firstName: "",
                lastName: "",
                email: "",
                isPrivateProfile: false,
                isDisallowNotificationMail: true,
                longitude: undefined,
                latitude: undefined,
                modifySuccessful: false,
                messageError: "",
                messageControl: "",
                modifySuccessfulAndEmailChanged: false,
                deleteSuccessful: false,
                messageErrorDeleteUser: "",
                isUpdateAccountInProgress: false,
                isDeleteAccountInProgress: false
            };
        },
        watch:{
            user(){
                if(!this.modifySuccessful){
                    this.initializeUser();
                }
            }
        },
        created() {
            this.initializeUser();
        },
        methods: {
            initializeUser(){
                this.resetControlsAndError();

                var jwt = utils.getCookie('jwt');

                var request = new Request("/api" + "/get_user", {
                    method: "POST",
                    body: JSON.stringify({ jwt:jwt }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data => {
                        if (response.ok) {
                            this.email = data.email;
                            this.firstName = data.firstName;
                            this.lastName = data.lastName;
                            this.pseudonym = data.pseudonym,
                            this.isPrivateProfile = data.isPrivateProfile,
                            this.isDisallowNotificationMail = data.isDisallowNotificationMail,
                            this.longitude = data.longitude;
                            this.latitude = data.latitude;
                            this.isUsePseudonym = (this.pseudonym !== "");
                        }else{
                            if(response.status === 401){
                                this.email = "";
                                this.firstName = "";
                                this.lastName = "";
                                this.pseudonym = "";
                                this.isPrivateProfile = false;
                                this.isDisallowNotificationMail = true;
                                this.longitude = "";
                                this.latitude = "";
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                                this.$emit("manage-user-connected", undefined);
                            }
                            this.messageError = data.message;
                        }
                    }).catch((error) => {
                        console.error(error);
                        this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                });
            },
            updateMarkerPosition(event){
                this.resetControlsAndError();
                this.longitude = event.latLng.lng().toFixed(6);
                this.latitude = event.latLng.lat().toFixed(6);
            },
            updateAccount() {

                if(this.isUpdateAccountInProgress){
                    return;
                }

                if(this.isUsePseudonym){
                    this.lastName = "";
                    this.firstName = "";
                }else{
                    this.pseudonym = "";
                }

                this.resetControlsAndError();

                if(this.firstName.length === 0 && !this.isUsePseudonym){
                    this.messageControl = "Veuillez renseigner votre prénom";
                    return;
                }
                if(this.firstName.length > 128 && !this.isUsePseudonym){
                    this.messageControl = "Le prénom doit comporter 128 caractères au maximum.";
                    return;
                }
                if(this.lastName.length === 0  && !this.isUsePseudonym){
                    this.messageControl = "Veuillez renseigner votre prénom";
                    return;
                }
                if(this.lastName.length > 128 && !this.isUsePseudonym){
                    this.messageControl = "Le nom doit comporter 128 caractères au maximum.";
                    return;
                }
                if(this.pseudonym.length === 0  && this.isUsePseudonym){
                    this.messageControl = "Veuillez renseigner votre pseudonyme";
                    return;
                }
                if(this.pseudonym.length > 128 && this.isUsePseudonym){
                    this.messageControl = "Le pseudonyme doit comporter 128 caractères au maximum.";
                    return;
                }
                if(this.email.length === 0){
                    this.messageControl = "Veuillez renseigner votre adresse courriel";
                    return;
                }
                if(this.email.length > 128){
                    this.messageControl = "L'adresse mail doit comporter 128 caractères au maximum.";
                    return;
                }

                this.isUpdateAccountInProgress = true;

                var parameters = {
                    jwt:utils.getCookie('jwt'),
                    firstName: this.firstName,
                    lastName: this.lastName,
                    pseudonym: this.pseudonym,
                    isPrivateProfile: this.isPrivateProfile,
                    isDisallowNotificationMail: this.isDisallowNotificationMail,
                    longitude: this.longitude,
                    latitude: this.latitude,
                    email: this.email,
                };
                var request = new Request("/api" + "/update_user", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                    'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            if(data.isEmailChanged){
                                this.modifySuccessfulAndEmailChanged = true;
                                utils.setCookie("jwt", "", 1);
                                setTimeout(function () {
                                    this.$emit("manage-user-connected", undefined);
                                    this.$router.push({
                                        name: "Home"
                                    });
                                }.bind(this), 5000);
                            }else{
                                utils.setCookie("jwt", data.jwt, 1);
                                this.modifySuccessful = true;
                                this.$emit("manage-user-connected", data.user);
                                setTimeout(function () {
                                    this.modifySuccessful = false;
                                }.bind(this), 5000);
                            }
                            
                        }else{
                            this.messageError = data.message;
                        }
                        this.isUpdateAccountInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isUpdateAccountInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isUpdateAccountInProgress = false;
                });
            },
            deleteAccount(){
                if(this.isDeleteAccountInProgress){
                    return;
                }
                this.isDeleteAccountInProgress = true;
                this.resetControlsAndErrorDeleteUser();

                var parameters = {
                    jwt:utils.getCookie('jwt'),
                    password: this.password
                };
                var request = new Request("/api" + "/delete_user", {
                    method: "DELETE",
                    body: JSON.stringify(parameters),
                    headers: {
                    'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            utils.setCookie("jwt", "", 1);
                            this.deleteSuccessful = true;
                            setTimeout(function () {
                                //Close the modal
                                this.$emit("manage-user-connected", undefined);
                                this.$refs.closeModalDeleteUser.click();
                                this.$router.push({
                                    name: "Home"
                                });
                            }.bind(this), 1500);
                        }else{
                            this.messageErrorDeleteUser = data.message;
                        }
                        this.isDeleteAccountInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageErrorDeleteUser = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isDeleteAccountInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageErrorDeleteUser = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isDeleteAccountInProgress = false;
                });
            },
            resetControlsAndError(){
                this.modifySuccessful = false;
                this.modifySuccessfulAndEmailChanged = false;
                this.messageError= "";
                this.messageControl = "";
            },
            resetControlsAndErrorDeleteUser(){
                this.deleteSuccessful = false;
                this.messageErrorDeleteUser= "";
            }
        }
    } 
</script>
<style>
    .map300{
        height: 320px;
    }
</style>