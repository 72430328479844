<template>
    <footer class="footer">
        <div style="text-align: center;">
            <a href="#" @click="redirect('TermsOfUse')"><span class="text-muted mr-2">Mentions légales</span></a>
            -
            <a href="#" @click="redirect('PersonalData')"><span class="text-muted ml-2">Données personnelles</span></a>
        </div>
    </footer>
</template>
<script>
    export default {
        name: "Footer",
        methods: {
            redirect(nextComposant){
                this.$router.push({
                    name: nextComposant
                });
            }
        }
    }
</script>
<style>
    .footer {
        position: absolute;
        padding-bottom: 0;
        width: 100%;
        height: 40px;
        line-height: 40px;
        background-color: #f5f5f5;
    }
</style>