<template>
    <div class="row" style="margin-bottom: 15px;">
        <div class="col-12 col-lg-6 separator-right">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="lastName">Veuillez saisir le code envoyé par mail</label>
                        <input type="text" class="form-control" maxlength="4" style="max-width:100px;" name="validationCode" id="validationCode" v-model="validationCode" v-on:keyup.enter="validateCode" v-on:input="resetControlsAndError"/>
                    </div>
                </div>
            </div>
            <div class="row" v-if="messageControl !== ''">
                <div class="col-12">
                    <div class="alert alert-danger">
                        {{messageControl}}
                    </div>
                </div>
            </div>
            <div class="row" v-if="validationSuccessful" style="margin-top:15px;">
                <div class="col-12">
                    <div class="alert alert-success">
                        <font-awesome-icon :icon="['far', 'check-circle']" class="mr-2"/>Le code est valide. Vous êtes désormais connecté(e).
                    </div>
                </div>
            </div>
            <div class="row" v-if="!validationSuccessful">
                <div class="col-12">
                    <button class='btn btn-primary' @click="validateCode"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isValidateCodeInProgress"/>Valider</button>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12">
                    Pas de mail reçu ? Peut-être que le mail a été classé comme indésirable. Sinon, vous pouvez demander à renvoyer un mail.
                </div>
            </div>
            <div class="row" v-if="resendValidationCodeSuccessful" style="margin-top:15px;">
                <div class="col-12">
                    <div class="alert alert-success">
                        <font-awesome-icon :icon="['far', 'envelope']" class="mr-2"/>Un nouveau mail avec le code de validation vient d'être envoyé.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button class='btn btn-primary mt-2' @click="sendCodeValidation"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isResendValidationCodeInProgress"/>Renvoyer le code par mail</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="messageError !== ''" style="margin-top:15px;">
        <div class="col-12">
            <div class="alert alert-danger">
                {{messageError}}
            </div>
        </div>
    </div>
</template>
<script>
    import utils from "@/utils/utils.js";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    import { faCheckCircle, faEnvelope } from '@fortawesome/free-regular-svg-icons'
    library.add(faCheckCircle, faEnvelope, faSpinner);
    export default {
        name: "ValidationCode",
        emits: ["manage-user-connected"],
        data() {
            return {
                validationCode: "",
                validationSuccessful: false,
                resendValidationCodeSuccessful: false,
                messageError: "",
                messageControl: "",
                isResendValidationCodeInProgress: false,
                isValidateCodeInProgress: false
            };
        },
        methods: {
            validateCode() {

                if(this.isValidateCodeInProgress){
                    return;
                }

                this.resetControlsAndError();

                if(this.validationCode.length === 0){
                    this.messageControl = "Veuillez renseigner le code de validation";
                    return;
                }

                this.isValidateCodeInProgress = true;

                var parameters = {
                    jwt:utils.getCookie('jwt'),
                    validationCode: this.validationCode,
                    language: "fr"
                };

                var request = new Request("/api" + "/validate_code", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            this.validationSuccessful = true;
                            utils.setCookie("jwt", data.jwt, 1);
                            setTimeout(function () {
                                this.$emit("manage-user-connected", data.user);
                            }.bind(this), 2500);
                        } else {
                            if(response.status === 401){
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                            }
                            this.messageError=data.message;
                        }
                        this.isValidateCodeInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isValidateCodeInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isValidateCodeInProgress = false;
                });
            },
            resetControlsAndError(){
                this.validationSuccessful = false;
                this.messageError= "";
                this.messageControl = "";
            },
            sendCodeValidation(){
                if(this.isResendValidationCodeInProgress){
                    return;
                }
                this.isResendValidationCodeInProgress = true;
                var parameters = {
                    jwt:utils.getCookie('jwt'),
                    language: "fr"
                };

                var request = new Request("/api" + "/resend_validation_code", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            this.resendValidationCodeSuccessful = true;
                            setTimeout(function () {
                                this.resendValidationCodeSuccessful = false;
                            }.bind(this), 5000);
                        } else {
                            if(response.status === 401){
                                // remove jwt
                                utils.setCookie("jwt", "", 1);
                            }

                            this.messageError = data.message;
                        }
                        this.isResendValidationCodeInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isResendValidationCodeInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError = "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isResendValidationCodeInProgress = false;
                });
            }
        }
    }
</script>