<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h4 class="title">
                    <span>
                        Mentions légales
                    </span>
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>Propriétaire et concepteur du site</h3>
                <p>
                    Nicolas Le Texier
                    <br/>
                    58 rue des Collinnes
                    <br/>
                    44300 Nantes
                    <br/>
                    France
                    <br/>
                    Adresse mail : contact@bookchain.fr
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>Hébergeur du site</h3>
                <p>
                    OVH
                    <br/>
                    2 rue Kellermann
                    <br/>
                    59100 Roubaix
                    <br/>
                    France
                    <br/>
                    www.ovh.com
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>Données personnelles</h3>
                <p>
                   Dans le cadre de la mise en place du R.G.P.D. une page consacrée aux données personnelles est <a href="#" @click="redirect('PersonalData')">consultable ici.</a>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>Propriété intellectuelle</h3>
                <p>
                    Les cartographies sont réalisées grâce à https://developers.google.com/maps/apis-by-platform
                </p>
                <p>
                    Les informations sur les livres sont recherchées à partir du numéro ISBN auprès des services suivants :
                    <ul>
                        <li>https://openlibrary.org/</li>
                        <li>https://developers.google.com/books/</li>
                        <li>http://classify.oclc.org/classify2/</li>
                    </ul>
                </p>
                <p>
                    La police de caractères utilisée dans l'écran de création d'une chaîne est issue de http://fr.fontriver.com/font/chopin_script/
                </p>
                <p>
                    Les images de couverture des livres sont issues de https://www.abebooks.com/
                </p>
                <p>
                    Les autres images utilisées pour agrémenter le site sont issues de https://pixabay.com :
                    <ul>
                        <li>Photo de bibliothèque en plan large sur la page d'accueil : https://pixabay.com/fr/photos/livres-%C3%A9tudiants-la-biblioth%C3%A8que-1281581/</li>
                        <li>Photo de livre lors de la création d'une chaîne : https://pixabay.com/fr/photos/livre-vieux-antique-table-en-bois-657618/</li>
                        <li>Image de livre dans le mode d'emploi sur la page d'accueil : https://pixabay.com/fr/vectors/livre-litt%C3%A9rature-pages-papier-1699641/</li>
                        <li>Images d'avatars dans le mode d'emploi sur la page d'accueil : https://pixabay.com/fr/illustrations/les-avatars-avatar-personnes-5033765/</li>
                        <li>Image dans la page en cas d'erreur 404 : https://pixabay.com/fr/photos/livre-br%C3%BBl%C3%A9-pages-br%C3%BBl%C3%A9es-pages-5562200/</li>
                        <li>Image des mains sur la page d'accueil : https://pixabay.com/fr/illustrations/livre-main-l-%C3%A9ducation-apprendre-4541987/ et https://pixabay.com/fr/illustrations/v%C3%A9rification-rapport-loupe-auditeur-4576720/</li>
                    </ul>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TermsOfUse",
        methods: {
            redirect(nextComposant){
                this.$router.push({
                    name: nextComposant
                });
            }
        }
    } 
</script>