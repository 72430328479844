<template>
    <div class="row">
        <div class="col-12" >
            <div class="form-group">
                <label for="lastName">Veuillez saisir votre adresse email :</label>
                <input type="email" class="form-control" name="email" id="email" v-model="email" v-on:input="resetControlsAndError"/>
            </div>
        </div>
    </div>
    <div class="row" v-if="messageControl !== ''">
        <div class="col-12 alert alert-danger">
            {{messageControl}}
        </div>
    </div>
    <div class="row" v-if="sendNewPasswordSuccessful">
        <div class="col-12">
            <div class="alert alert-success">
                <font-awesome-icon :icon="['far', 'envelope']" class="mr-2"/>Un mail a été envoyé à cette adresse, si un compte existe pour cette adresse.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button class='btn btn-primary' @click="sendNewPassword"><font-awesome-icon icon="spinner" class="mr-2 loading" v-if="isSendPasswordInProgress"/>Réinitialiser le mot de passe</button>
            <button class='btn btn-secondary' style="float: right;" @click="cancelForgotPassword">Retour</button>
        </div>
    </div>
    <div class="row" v-if="messageError !== ''" style="margin-top:15px;">
        <div class="col-12">
            <div class="alert alert-danger">
                {{messageError}}
            </div>
        </div>
    </div>
</template>
<script>
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
    import { faSpinner } from '@fortawesome/free-solid-svg-icons'
    library.add(faEnvelope, faSpinner);
    export default {
        name: "ForgotPassword",
        emits: ["return-to-login"],
        props: {
            initialEmail: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                email: "",
                validationCode: "",
                sendNewPasswordSuccessful: false,
                messageError: "",
                messageControl: "",
                isSendPasswordInProgress: false
            };
        },
        created() {
            this.email = this.initialEmail;
        },
        methods: {
            sendNewPassword() {

                if(this.isSendPasswordInProgress){
                    return;
                }

                this.resetControlsAndError();

                if(this.email.length === 0){
                    this.messageControl = "Veuillez renseigner votre adresse courriel.";
                    return;
                }

                this.isSendPasswordInProgress = true;

                var parameters = {
                    email: this.email,
                    language: "fr"
                };

                var request = new Request("/api" + "/forgot_password", {
                    method: "POST",
                    body: JSON.stringify(parameters),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                fetch(request).then(response => {
                    response.json().then(data=> {
                        if (response.ok) {
                            this.sendNewPasswordSuccessful = true;
                            setTimeout(function () {
                                this.$emit("return-to-login");
                            }.bind(this), 3500);
                        } else {
                            this.messageError=data.message;
                        }
                        this.isSendPasswordInProgress = false;
                    }).catch((error) => {
                        console.error(error);
                        this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                        this.isSendPasswordInProgress = false;
                    });
                }).catch((error) => {
                    console.error(error);
                    this.messageError= "Erreur du serveur. Veuillez réessayer ultérieurement";
                    this.isSendPasswordInProgress = false;
                });
            },
            resetControlsAndError(){
                this.sendNewPasswordSuccessful = false;
                this.messageError= "";
                this.messageControl = "";
            },
            cancelForgotPassword(){
                this.$emit("return-to-login");
            }
        }
    }
</script>