<template>
    <Header :user="user" :isResetLinksToValidate="isResetLinksToValidate" @manage-user-connected="manageUserConnected($event)"/>
    <div style="min-height:calc(100% - 96px);">
        <router-view :user="user" @reset-links-to-validate="resetLinksToValidate($event)" @manage-user-connected="manageUserConnected($event)"/>
    </div>
    <Footer/>
</template>

<script>
    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";

    export default {
        name: 'App',
        components: {
            Header,
            Footer
        },
        data() {
            return {
                user: undefined,
                isResetLinksToValidate: false
            };
        },
        methods: {
            manageUserConnected(data){
                this.user = data;
                this.isResetLinksToValidate = !this.isResetLinksToValidate;
            },
            resetLinksToValidate(){
                this.isResetLinksToValidate = !this.isResetLinksToValidate;
            }
        }
    }
</script>