<template>
    <div style="background-color : #343a40;">
    <!--<div style="background-color : #9e988b;"> choix 2-->
    <!--<div> choix 3-->
        <div class="container" style="background-color : #343a40;">
        <!--<div class="container" style="background-color : #9e988b;"> cjoix 2-->
        <!--<div class="container" style="border-top: 1px solid #f2f2f2;"> choix 3-->
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <SearchChain :initialCodeChain="initialCodeChain"/>
                </div>
                <div class="col-md-12 col-lg-6">
                   <SearchIsbn :user="user"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import SearchChain from "@/components/SearchChain.vue";
    import SearchIsbn from "@/components/SearchIsbn.vue";
    export default {
        name: "SearchModule",
        components: { SearchChain, SearchIsbn },
        props: {
            user: {
                type: Object
            },
            initialCodeChain: String
        }
    }
</script>
